import React from "react";
import styled from "styled-components";

// Utils
import { Color, rem, responsive } from "../../utils/style";
import { Icons } from "../../utils/svg";

// Components
import RitualLogo from "../global/RitualLogo";
import Text from "../Text";

// Styled Elements
const SimpleNavWrapper = styled.div`
  width: 100%;
  height: 52px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border-top: 4px solid ${Color.ritualBlue};
  background-color: #fff;
  z-index: 10;

  svg {
    color: ${Color.ritualBlue};
  }

  ${responsive.sm`
    height: 60px;
  `};

  ${responsive.md`
    height: 76px;
  `};
`;

const LeftColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding-left: 32px;

  ${responsive.sm`
    padding-left: 34px;
  `};

  ${responsive.md`
    padding-left: 45px;
  `};

  ${responsive.lg`
    padding-left: 98px;
  `};
`;

const CenteredColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoWrapper = styled.div`
  svg {
    height: 16px;
    width: 51px;

    ${responsive.sm`
      height: 18px;
      width: 58px;
    `}

    ${responsive.md`
      height: 22px;
      width: 70px;
    `}
  }
`;

const RightColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding-right: 32px;

  ${responsive.sm`
    padding-right: 34px;
  `};

  ${responsive.md`
    padding-right: 45px;
  `};

  ${responsive.lg`
    padding-right: 98px;
  `};
`;

const BackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  appearance: none;
  background: none;
  border: none;
  color: ${Color.ritualBlue};

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }

  svg {
    width: 20px;
    height: 12px;
  }

  span {
    display: none;
    font-weight: 500;
    font-size: ${rem(16)};
    line-height: ${rem(26)};

    ${responsive.sm`
        display: block;
        margin-left: 16px;
    `};
  }
`;

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  appearance: none;
  background: none;
  border: none;

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

export default class Navigation extends React.Component {
  handleClick() {
    const { goToPreviousSlide } = this.props;
    goToPreviousSlide();
  }

  render() {
    const { currentSlide } = this.props;

    let displayBackButton = currentSlide.slideName !== "intro";

    return (
      <SimpleNavWrapper>
        <div className="row h-100 w-100">
          <LeftColumn className="col-2">
            {displayBackButton && (
              <BackButton onClick={this.handleClick.bind(this)}>
                <Icons.ArrowRoundedLeft />
                <span>
                  <Text
                    id="build-bundle.navigation.back"
                    defaultMessage="Back"
                  />
                </span>
              </BackButton>
            )}
          </LeftColumn>
          <CenteredColumn className="col-8 d-flex align-items-center justify-content-center">
            <LogoWrapper>
              <RitualLogo
                handleNavigateClick={this.props.onClose.bind(this, "/")}
              />
            </LogoWrapper>
          </CenteredColumn>
          <RightColumn className="col-2">
            <CloseButton
              onClick={this.props.onClose.bind(this, "/shop-bundles")}
              aria-label="Close"
            >
              <Icons.Close />
            </CloseButton>
          </RightColumn>
        </div>
      </SimpleNavWrapper>
    );
  }
}
