// Slides
import { kidsSlides } from "./default";

/*

Structure of a slide:

  {
    // Required
    slideName: String, // Used as a slide ID, should be unique

    // Required
    componentName: String, // Represents which slide component to use on build-a-bundle.js

    // Optional (required for Intermission slide)
    componentContent: Object = { // Used for Intermission slide content
      smallHeader: String, // Eyebrow copy for slide 
      title: String, // Title copy
      copy: String, // Body copy
      imageName: String, // References image from map in Intermission.js
      imageIngredient: String, // Ingredient name copy
      imageSource: String, // Ingredient source copy
    },

    // Required
    answers: Array = [ // Used to render buttons and trigger next route
      {
        copy: String, // Copy for button
        sku: String, // If SKU property exists, will add product to bundle when moving to next slide
        nextSlide: Object = {
          firstRun: String, // On first loop of quiz, go here (looks at slideName)
          onwards: String, // Subsequent loops, go here
        },
      },
    ],
  },

  When using age slide, `answer` array is now an object:

  answers: Object = {
    Male: Object = {
      under50: Object = {
        sku: String,
        nextSlide: Object = {
          firstRun: String,
          onwards: String,
        },
      },
      over50: Object ={
        sku: String,
        nextSlide: Object = {
          firstRun: String,
          onwards: String,
        },
      },
    },
    Female: Object = {
      under50: Object = {
        sku: String,
        nextSlide: Object = {
          firstRun: String,
          onwards: String,
        },
      },
      over50: Object = {
        sku: String,
        nextSlide: Object = {
          firstRun: String,
          onwards: String,
        },
      },
    },
  }

*/

export default {
  v1Kids: kidsSlides,
};
