import React from "react";
import { graphql } from "gatsby";

// Components
import BundleHouseholdFlow from "../../components/buildBundle/BundleHouseholdFlow";

export default class BuildABundlePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      discount: 10,
    };
  }

  render() {
    const { data } = this.props;
    const { discount } = this.state;

    const props = {
      data,
      discount,
    };

    return <BundleHouseholdFlow {...props} />;
  }
}

export const query = graphql`
  query BuildABundleQuery($locale: String!) {
    allProduct {
      nodes {
        id
        name
        sku
      }
    }
    allPlan {
      nodes {
        id
        product_id
        amount
      }
    }
    backgroundImage: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "3bVbTPVG81x6cnbnwWc9iP" }
    ) {
      description
      fluid(maxWidth: 1286, quality: 90) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    allContentfulProduct(filter: { node_locale: { eq: $locale } }) {
      nodes {
        name {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        headerDescription
        sku
        supplementFacts {
          servingSize
          servingsPerContainer
          labels {
            dv
          }
          nutrients {
            label
            dosage
            dv
          }
          ingredients {
            label
            dosage
            dv
          }
          notations {
            label
          }
          otherIngredients
          notationReferences
          attributions {
            label
          }
          simpleIngredients {
            name
            dosage
          }
        }
        heroFeaturedImages {
          title
          fluid(maxWidth: 120, quality: 90) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          description
        }
      }
    }
  }
`;
