import React from "react";
import styled from "styled-components";

import { Color, rem, responsive } from "../../utils/style";
import Text from "../Text";

const MessagingWrapper = styled.div`
  width: 100%;
  max-width: 256px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${responsive.sm`
    max-width: 370px;
  `};
`;

const Message = styled.p`
  color: ${Color.ritualBlue};
  font-weight: 300;
  font-size: ${rem(12)};
  line-height: ${rem(18)};
  text-align: center;
  margin: 16px 0 0 0;

  ${responsive.sm`
    font-size: ${rem(14)};
    line-height: ${rem(20)};
    margin-top: 24px;
  `};
`;

export default class NoProductMessage extends React.Component {
  renderMessage() {
    const { firstRun, over, under } = this.props;

    if (firstRun && under) {
      return (
        <Message>
          <Text
            id="build-bundle.no-product.first-under"
            defaultMessage="You must be 13 or over to shop Ritual.com. If you are 13 or over
          please update your age to proceed."
          />
        </Message>
      );
    }

    if (over) {
      return (
        <Message>
          <Text
            id="build-bundle.no-product.over"
            defaultMessage="We recommend speaking with your healthcare provider about which
          multivitamin is right for you."
          />
        </Message>
      );
    }

    if (under) {
      return (
        <Message>
          <Text
            id="build-bundle.no-product.under"
            defaultMessage="We're always working on something new. Stay tuned for more details
          coming soon."
          />
        </Message>
      );
    }
  }

  render() {
    const message = this.renderMessage();
    if (!message) return null;

    const { className = "mb-4" } = this.props;

    return <MessagingWrapper className={className}>{message}</MessagingWrapper>;
  }
}
