import React from "react";
import styled from "styled-components";

// Utils
import { Color, rem, responsive, rgba } from "../../utils/style";

// Components
import { Icons } from "../../utils/svg";

// Styled Elements
const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DropdownWrapper = styled.div`
  position: relative;
  outline: ${Color.ritualYellow};
  display: block;
  cursor: pointer;

  ${responsive.sm`
    margin: 0;
  `}

  svg {
    position: absolute;
    top: 28px;
    right: 32px;
    width: 16px;
    height: auto;
    z-index: 10;
    transform: ${p => (p.isOpen ? "rotate(0deg)" : "rotate(180deg)")};
    pointer-events: none;

    ${responsive.sm`
      top: 37px;
      right: 48px;
      width: 20px;
    `};

    g {
      fill: ${Color.ritualDisabledBlue};
    }
  }

  [data-whatintent="mouse"] & {
    display: none;
  }
`;

const NativeDropdown = styled.select`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 16px 32px;
  border-radius: 16px;
  width: 100%;
  border: none;
  background-color: ${Color.white};
  appearance: none;

  height: 64px;

  font-size: ${rem(22)};
  line-height: ${rem(32)};
  letter-spacing: -0.2px;
  color: ${p => (p.isPlaceholder ? Color.fadedBlue : Color.ritualBlue)};
  font-weight: 500;

  ${responsive.sm`
    height: 84px;
    font-size: ${rem(30)};
    line-height: ${rem(36)};
    letter-spacing: -0.4px;
    padding: 24px 48px;
  `};

  &.small {
    font-size: ${rem(16)};
    line-height: ${rem(26)};
    letter-spacing: 0;

    ${responsive.sm`
      font-size: ${rem(22)};
      line-height: ${rem(32)};
      letter-spacing: -0.2px;
      padding: 26px 48px;
    `};
  }
`;

const DropdownGradient = styled.div`
  pointer-events: none;
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  width: 80px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  z-index: 1;

  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 16%,
    rgba(255, 255, 255, 1) 100%,
    rgba(255, 255, 255, 0) 100%
  );
`;

const CustomSelect = styled.div`
  position: relative;
  width: 100%;
  display: none;
  background: transparent;
  border: none;
  padding: 0;
  text-align: left;
  color: ${Color.ritualBlue};

  [data-whatintent="mouse"] & {
    display: block;
    outline: none;
  }
`;

const CustomSelectTrigger = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 16px 32px;
  border-radius: 16px;
  background-color: ${Color.white};

  /* Hide overflowing text */
  overflow: hidden;
  white-space: nowrap;

  font-size: ${rem(22)};
  line-height: ${rem(32)};
  letter-spacing: -0.2px;
  color: ${p => (p.isPlaceholder ? Color.fadedBlue : Color.ritualBlue)};
  font-weight: 500;

  ${responsive.sm`
    font-size: ${rem(30)};
    line-height: ${rem(36)};
    letter-spacing: -0.4px;
    padding: 24px 48px;
  `};

  svg {
    position: absolute;
    top: 28px;
    right: 32px;
    width: 16px;
    height: auto;
    transform: ${p => (p.isOpen ? "rotate(0deg)" : "rotate(180deg)")};
    z-index: 10;

    ${responsive.sm`
      top: 37px;
      right: 48px;
      width: 20px;
    `};

    g {
      transition: fill 0.1s;
      fill: ${p => (p.isOpen ? Color.ritualBlue : Color.fadedBlue)};
    }
  }

  &.small {
    font-size: ${rem(16)};
    line-height: ${rem(26)};
    letter-spacing: 0;

    svg {
      top: 24px;
    }

    ${responsive.sm`
      font-size: ${rem(22)};
      line-height: ${rem(32)};
      letter-spacing: -0.2px;
      padding: 26px 48px;

      svg {
        top: 37px;
      }
    `};

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
      z-index: 1;

      background: rgb(255, 255, 255);
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 16%,
        rgba(255, 255, 255, 1) 100%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }

  &:hover {
    svg {
      g {
        fill: ${Color.ritualBlue};
      }
    }
  }
`;

const CustomSelectOptions = styled.div`
  display: ${p => (p.isOpen ? "block" : "none")};
  position: absolute;
  top: calc(100% - 10px);
  left: 0;
  right: 0;
  z-index: 40;
`;

const CustomSelectOption = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
  background-color: ${Color.white};

  padding: 8px 32px;
  font-size: ${rem(22)};
  line-height: ${rem(32)};
  letter-spacing: 0;
  font-weight: 500;
  color: ${Color.ritualBlue};

  :hover {
    color: ${rgba(Color.ritualBlue, 0.56)};
  }

  &:last-of-type {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    padding-bottom: 16px;
  }

  ${responsive.sm`
    padding: 12px 48px;

    font-size: ${rem(30)};
    line-height: ${rem(36)};
    letter-spacing: -0.4px;

    &:last-of-type {
      padding-bottom: 24px;
    }
  `};

  &.small {
    padding: 14px 32px;
    font-size: ${rem(14)};
    line-height: ${rem(20)};
    letter-spacing: 0;
    font-weight: 300;

    &:last-of-type {
      padding-bottom: 18px;
    }

    ${responsive.sm`
      padding: 12px 48px;

      font-size: ${rem(18)};
      line-height: ${rem(28)};
      letter-spacing: 0px;

      &:last-of-type {
        padding-bottom: 32px;
      }
    `};
  }
`;

const ShadowBorder = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: calc(-46px * ${p => p.height});
  left: 0;
  border-radius: 16px;
  box-shadow: 0px 8px 30px -10px rgba(20, 43, 111, 0.16);
`;

/**
 *
 * @param {Array} options array of options for dropdown. NOTE: first object in array is used as placeholder
 * @param {Object} currentOption current selected option object (also exists in options array)
 * @param {Function} onSelectionMade passes new selection back to parent for setting state
 */
export default class QuizDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.watchClickOutside = this.watchClickOutside.bind(this);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.watchClickOutside);
  }

  toggleCustomSelect() {
    const isClosed = !this.state.isOpen;

    if (isClosed) {
      this.openSelectCustom();
    } else {
      this.closeSelectCustom();
    }
  }

  openSelectCustom() {
    this.setState({
      isOpen: true,
    });

    // Add related event listeners
    document.addEventListener("click", this.watchClickOutside);
  }

  closeSelectCustom() {
    this.setState({
      isOpen: false,
    });

    // Remove related event listeners
    document.removeEventListener("click", this.watchClickOutside);
  }

  watchClickOutside(e) {
    const customSelectElement = document.getElementsByClassName(
      "customSelect",
    )[0];
    const didClickedOutside = !customSelectElement.contains(e.target);
    if (didClickedOutside) {
      this.closeSelectCustom();
    }
  }

  handleOptionClick(newCurrentValue) {
    this._onSelectionMade(newCurrentValue);
  }

  handleOptionEnter(e) {
    let newCurrentValue = e.target.value;

    this._onSelectionMade(newCurrentValue);
  }

  _onSelectionMade(value) {
    this.setState({
      isOpen: false,
    });

    this.props.onSelectionMade(value);
  }

  renderNativeOptions() {
    const { options } = this.props;

    return options.map((option, index) => {
      return (
        <option key={index} value={option.value} disabled={option.disabled}>
          {option.title}
        </option>
      );
    });
  }

  render() {
    const {
      className,
      currentOption,
      options,
      smallOptions,
      smallPlaceholder,
    } = this.props;
    const { isOpen } = this.state;

    const selectedOption =
      currentOption && currentOption.value ? currentOption : options[0];

    return (
      <SelectWrapper className={className}>
        <DropdownWrapper>
          <NativeDropdown
            onChange={this.handleOptionEnter.bind(this)}
            value={selectedOption.value}
            aria-labelledby="productLabel"
            isPlaceholder={selectedOption.value === options[0].value}
            className={smallPlaceholder ? "small" : ""}
          >
            {this.renderNativeOptions()}
          </NativeDropdown>
          <Icons.CaretUpRounded />
          {smallPlaceholder && <DropdownGradient />}
        </DropdownWrapper>

        {/* <!-- Hide the custom select from Assistive Tech (e.g. Screen Readers) using aria-hidden --> */}
        <CustomSelect
          className={`customSelect ${isOpen ? "isOpen" : ""}`}
          onClick={this.toggleCustomSelect.bind(this)}
          aria-hidden={!isOpen}
        >
          <CustomSelectTrigger
            isOpen={isOpen}
            isPlaceholder={selectedOption.value === options[0].value}
            className={smallPlaceholder ? "small" : ""}
          >
            {selectedOption.title}
            <Icons.CaretUpRounded />
          </CustomSelectTrigger>
          <CustomSelectOptions isOpen={isOpen}>
            {options.map((option, index) => {
              if (option.disabled) return null;

              return (
                <CustomSelectOption
                  onClick={this.handleOptionClick.bind(this, option.value)}
                  key={index}
                  value={option.value}
                  active={selectedOption.value === option.value}
                  disabled={option.disabled}
                  className={smallOptions ? "small" : ""}
                >
                  {option.title}
                </CustomSelectOption>
              );
            })}
          </CustomSelectOptions>
          {isOpen && <ShadowBorder height={options.length - 1} />}
        </CustomSelect>
      </SelectWrapper>
    );
  }
}
