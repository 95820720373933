import React from "react";
import styled from "styled-components";

// Services
import intl from "../../services/intl";

// Utils
import { Color, rem, responsive } from "../../utils/style";
import { Icons } from "../../utils/svg";
import { ProductSKU } from "../../utils/product";

// Components
import QuizDropdown from "./QuizDropdown";
import QuizInput from "./QuizInput";
import NoProductMessage from "./NoProductMessage";

// Styled Elements
const HouseholdMemberWrapper = styled.div.attrs({
  className: "d-flex align-items-center justify-content-center flex-column",
})`
  z-index: ${p => 10 + p.index};
  margin-bottom: 30px;

  ${responsive.sm`
    margin-bottom: 40px;
  `}

  &:last-of-type {
    margin-bottom: 24px;

    ${responsive.sm`
      margin-bottom: 32px;
    `}
  }
`;

const MemberTitle = styled.h2`
  position: relative;
  font-size: ${rem(12)};
  line-height: ${rem(20)};
  letter-spacing: 0.8px;
  font-weight: 500;
  text-transform: uppercase;
  color: ${Color.lessFadedBlue};
  text-align: center !important;

  width: 100%;
  margin-bottom: 16px;

  ${responsive.sm`
    font-size: ${rem(16)};
    line-height: ${rem(26)};
    letter-spacing: 1px;
  `};
`;

const RemoveButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;

  padding: 0;
  margin: 0;
  border: none;
  appearance: none;

  display: flex;
  align-items: center;
  justify-content: center;
  background: none;

  opacity: 0.24;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }

  ${responsive.sm`
    top: -3px;
  `};

  svg {
    width: 24px;
    height: 24px;

    ${responsive.sm`
      width: 32px;
      height: 32px;
    `};
  }

  [data-whatintent="mouse"] & {
    outline: none;
  }
`;

const numberToWords = {
  1: "First",
  2: "Second",
  3: "Third",
  4: "Fourth",
  5: "Fifth",
  6: "Sixth",
};

const productOptions = {
  over50: [
    {
      value: "over50disabled",
      title: intl.t("build-bundle.slides.household.interested", "Interested In"),
      disabled: true,
    },
    {
      value: ProductSKU.EFW60,
      title: intl.t("build-bundle.slides.household.EFW60", "Daily nutrient support"),
    },
    {
      value: ProductSKU.E50PLUS60,
      title: intl.t(
        "build-bundle.slides.household.E50PLUS60",
        "Post-menopause nutrient support",
      ),
    },
  ],
  under50: [
    {
      value: "under50disabled",
      title: intl.t("build-bundle.slides.household.interested", "Interested In"),
      disabled: true,
    },
    {
      value: ProductSKU.EFW60,
      title: intl.t("build-bundle.slides.household.EFW60", "Daily nutrient support"),
    },
    {
      value: ProductSKU.EPRE60,
      title: intl.t(
        "build-bundle.slides.household.EPRE60",
        "Prenatal nutrient support",
      ),
    },
    {
      value: ProductSKU.EPOST60,
      title: intl.t(
        "build-bundle.slides.household.EPOST60",
        "Postnatal nutrient support",
      ),
    },
  ],
};

export default class HouseholdMember extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showSecondaryDropdown: false,
      under: false,
      over: false,
    };

    this.timeout = null;
    this.debounce = null;
  }

  componentDidMount() {
    const {
      user: { age, gender },
    } = this.props;

    if (age || gender) {
      const showSecondaryDropdown = this._getSecondaryState(gender, age);

      this.setState(
        {
          showSecondaryDropdown,
        },
        () => {
          this._checkDisabledState();
        },
      );
    }
  }

  componentWillUnmount() {
    if (this.debounce) {
      clearTimeout(this.debounce);
    }
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  removeUser(index, e) {
    e.preventDefault();
    this.props.removeUser(index);
  }

  handleGenderChange(value) {
    const {
      userIndex,
      user: { age },
    } = this.props;

    const showSecondaryDropdown = this._getSecondaryState(value, age);

    this.setState({
      showSecondaryDropdown,
    });

    this.props.onGenderChange(value, userIndex);

    const product = this._checkProductLogic(value, age);
    this.props.onProductChange(product, userIndex);
  }

  handleAgeChange(value) {
    if (this.debounce) {
      clearTimeout(this.debounce);
    }

    const age = Number(value);

    const {
      user: { gender },
      userIndex,
    } = this.props;

    const showSecondaryDropdown = this._getSecondaryState(gender, age);

    this.setState({
      showSecondaryDropdown,
      under: false,
      over: false,
    });

    this.props.onAgeChange(age, userIndex);

    if (!showSecondaryDropdown) {
      // If no secondary dropdown, get corresponding product
      const product = this._checkProductLogic(gender, age);
      this.props.onProductChange(product, userIndex);
    } else {
      // If the age updates the secondary, we clear it to reduce product add errors
      // where they may have been under 50, then changed to over 50
      this.props.onProductChange(undefined, userIndex);
    }
  }

  _getSecondaryState(gender, age) {
    // Only show secondary dropdown for 18-50 and 50+
    return gender === "Female" && age >= 18 && age < 100;
  }

  _checkProductLogic(gender, age) {
    if (gender === "Male") {
      const ageNumber = Number(age);

      if (ageNumber >= 4 && ageNumber < 13) {
        return ProductSKU.EFKIDS90;
      }

      if (ageNumber >= 13 && ageNumber < 18) {
        return ProductSKU.EFTB60;
      }

      if (ageNumber >= 18 && ageNumber < 50) {
        return ProductSKU.EFM60;
      }

      if (ageNumber >= 50 && ageNumber < 100) {
        return ProductSKU.EFM50PLUS60;
      }
    } else if (gender === "Female") {
      const ageNumber = Number(age);

      if (ageNumber >= 4 && ageNumber < 13) {
        return ProductSKU.EFKIDS90;
      }

      if (ageNumber >= 13 && ageNumber < 18) {
        return ProductSKU.EFTG60;
      }
      // Ages 18-50 and 50+ are determined by secondary dropdown
    }

    return undefined;
  }

  handleProductChange(value) {
    this.props.onProductChange(value, this.props.userIndex);
  }

  renderProductDropdown() {
    const { user } = this.props;

    const over50 = user.age >= 50;
    const options = over50 ? productOptions.over50 : productOptions.under50;

    const title =
      options.find(o => o.value === user.product)?.title ||
      intl.t("build-bundle.slides.household.interested", "Interested In");

    let currentProductOption = {
      title,
      value: user.product,
      disabled:
        title === intl.t("build-bundle.slides.household.interested", "Interested In"),
    };

    return (
      <QuizDropdown
        className="secondary-dropdown mt-2 mt-sm-3"
        smallOptions={true}
        smallPlaceholder={!!user.product}
        options={options}
        currentOption={currentProductOption}
        onSelectionMade={this.onFieldChange.bind(
          this,
          this.handleProductChange.bind(this),
        )}
      />
    );
  }

  onFieldChange(callback, value) {
    callback(value);

    // Check on next tick, once new props have been updated
    this.timeout = setTimeout(() => {
      this._checkDisabledState();
    }, 0);
  }

  _checkDisabledState() {
    const underAgeThreshold = 4;

    const { user, userIndex } = this.props;
    const { age, gender, product } = user;

    const showSecondaryDropdown = this._getSecondaryState(gender, age);

    let disabled = !gender || !age;

    if (showSecondaryDropdown && !product) {
      disabled = true;
    }

    this.props.updateDisabledState(userIndex - 1, disabled);

    // Keep the input enabled, but delay showing the over/under messages.
    this.debounce = setTimeout(() => {
      this.setState({
        under: age && age !== "" && age < underAgeThreshold,
        over: age && age !== "" && age > 100,
      });
    }, 800);
  }

  render() {
    const { canRemove = true, user, userIndex } = this.props;
    const { showSecondaryDropdown, over, under } = this.state;

    const currentGenderOption = {
      value: user.gender,
      title: user.gender,
    };

    return (
      <HouseholdMemberWrapper index={userIndex}>
        <MemberTitle>
          {numberToWords[userIndex]} Person
          {canRemove && (
            <RemoveButton onClick={this.removeUser.bind(this, userIndex)}>
              <Icons.Remove />
            </RemoveButton>
          )}
        </MemberTitle>
        <QuizDropdown
          className="mb-2 mb-sm-3"
          currentOption={currentGenderOption}
          options={[
            {
              title: intl.t("general.sex", "Sex"),
              value: "Sex",
              disabled: true,
            },
            {
              title: intl.t("general.female", "Female"),
              value: "Female",
            },
            {
              title: intl.t("general.male", "Male"),
              value: "Male",
            },
          ]}
          onSelectionMade={this.onFieldChange.bind(
            this,
            this.handleGenderChange.bind(this),
          )}
        />
        <QuizInput
          value={user.age}
          onChange={this.onFieldChange.bind(
            this,
            this.handleAgeChange.bind(this),
          )}
          placeholder={intl.t("general.age", "Age")}
          length={3}
        />
        {showSecondaryDropdown && this.renderProductDropdown()}

        <NoProductMessage
          className="mb-2"
          firstRun={false}
          over={over}
          under={under}
        />
      </HouseholdMemberWrapper>
    );
  }
}
