import React from "react";
import styled from "styled-components";

// Utils
import { rem, responsive } from "../../../utils/style";

// Components
import QuizButton from "../QuizButton";
import YellowArrow from "../YellowArrow";
import Text from "../../Text";

// Styled Elements
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 56px 24px;

  ${responsive.sm`
    max-width: 500px;
    margin: 80px auto;
  `};

  ${responsive.lg`
    margin: 120px auto;
  `}
`;

const Copy = styled.div`
  margin-bottom: 24px;

  h1 {
    font-size: ${rem(26)};
    letter-spacing: -0.3px;
    line-height: ${rem(36)};
    text-align: center;
    margin-bottom: 0;
  }

  ${responsive.sm`
    margin-bottom: 40px;
    h1 {
      font-size: ${rem(48)};
      letter-spacing: -1.4px;
      line-height: ${rem(54)};
      width: 470px;
    }
  `}
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;

  button {
    margin-bottom: 8px;

    ${responsive.sm`
        margin-bottom: 16px;
    `}

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Answer = styled(QuizButton)`
  font-size: ${rem(20)};

  ${responsive.sm`
    font-size: ${rem(22)};
    line-height: ${rem(32)};
  `};
`;

const ArrowWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 256px;
  margin: 0 auto;

  ${responsive.sm`
    width: auto;
    max-width: unset;
  `};
`;

const Arrow = styled(YellowArrow)`
  position: absolute;
  left: -11px;
  bottom: -40px;
  transform: rotate(48deg);

  @media (min-width: 375px) {
    left: -33px;
    bottom: -25px;
  }

  ${responsive.sm`
    left: -54px;
    bottom: -39px;
    transform: rotate(50deg);
  `};
`;

export default class TakeVitamins extends React.Component {
  render() {
    let { slide, nextSlide } = this.props;
    return (
      <Wrapper>
        <Copy>
          <ArrowWrapper>
            <h1>
              <Text
                id="build-bundle.slides.take-vitamins.heading"
                defaultMessage="Do you currently take vitamin supplements?"
              />
            </h1>
            <Arrow />
          </ArrowWrapper>
        </Copy>
        <Buttons>
          {slide.answers.map((answer, i) => {
            return (
              <Answer
                clickHandler={() => {
                  nextSlide(answer);
                }}
                key={i}
              >
                {answer.copy}
              </Answer>
            );
          })}
        </Buttons>
      </Wrapper>
    );
  }
}
