import React from "react";
import styled from "styled-components";

// Utils
import { media, Color, rem, Font, responsive } from "../../utils/style";

// Components
import MagicLink from "../MagicLink";
import Text from "../Text";

// Styled Elements
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 50px 20px 112px;

  ${responsive.sm`
    max-width: 534px;
    margin: 48px auto;
  `};

  ${responsive.md`
    max-width: unset;
    width: 100%;
    margin: 56px 0;
    padding: 0 24px;
  `};
`;

const DisclaimerCopy = styled.p`
  ${Font.circular}
  font-size: ${rem(12)};
  line-height: ${rem(18)};
  font-weight: 500;
  padding: 16px;
  margin: 0 0 16px;
  color: ${Color.ritualBlue};
  border: 1px solid rgba(0, 0, 0, 0.16);
`;

const FDALink = styled.p`
  ${Font.circular}
  font-size: ${rem(12)};
  line-height: ${rem(18)};
  font-weight: 500;
  margin: 0;
  color: ${Color.ritualBlue};

  a {
    border-bottom: 1px solid ${Color.ritualBlue};
    transition: all 0.2s;

    &:hover {
      opacity: 0.56;
    }
  }
`;

export default class Disclaimer extends React.Component {
  render() {
    return (
      <Wrapper>
        <DisclaimerCopy>
          <Text
            id="build-bundle.disclaimer.text"
            defaultMessage={`* These statements have not been evaluated by the Food and Drug
              Administration. This product is not intended to diagnose, treat, cure
              or prevent any disease.`}
          />
        </DisclaimerCopy>
        <FDALink>
          <Text
            id="build-bundle.disclaimer.fda-text"
            defaultMessage="The FDA plays a specific role in regulating the nutraceutical
            industry."
          />{" "}
          <MagicLink
            to="/articles/the-fdas-role-in-the-supplement-industry"
            title="Read more about the FDAs role in the supplement industry."
            ariaLabel="Read more about the FDAs role in the supplement industry."
          >
            <Text
              id="build-bundle.disclaimer.link"
              defaultMessage="Learn more"
            />
          </MagicLink>
          .
        </FDALink>
      </Wrapper>
    );
  }
}
