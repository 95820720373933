import React from "react";
import styled from "styled-components";

// Utils
import { Color, rem, responsive } from "../../utils/style";

// Styled Elements
const Button = styled.button`
  color: ${Color.ritualBlue};
  font-size: ${rem(22)};
  line-height: ${rem(30)};
  text-align: center;
  user-select: none;
  outline: none;
  border: none;

  background-color: ${Color.white};
  padding: 16px 40px;
  border-radius: 16px;

  &:hover {
    padding: 14px 38px;
    border: 2px solid ${Color.ritualBlue};
  }

  &:active {
    outline: none;
    border: 2px solid ${Color.ritualBlue};
  }

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }

  &.disabled {
    opacity: 0.24;
    cursor: default;
    pointer-events: none;

    &:hover {
      background-color: ${Color.ritualBlue} !important;
      border: 2px solid ${Color.ritualBlue} !important;

      span {
        color: white !important;

        &:before {
          background-color: white !important;
        }
      }
    }
  }

  ${responsive.sm`
    font-size: ${rem(30)};
    letter-spacing: -0.4px;
    line-height: ${rem(36)};
    text-align: center;
    padding: 24px 48px;

    &:hover {
      padding: 22px 46px;
      border: 2px solid ${Color.ritualBlue};
    }
  `};
`;

export default class QuizButton extends React.Component {
  handleClick() {
    if (document && document.documentElement) {
      document.documentElement.scrollTop = 0;
    }
    this.props.clickHandler();
  }

  render() {
    let { children, className, disabled } = this.props;

    return (
      <Button
        className={className}
        disabled={disabled}
        onClick={this.handleClick.bind(this)}
      >
        {children}
      </Button>
    );
  }
}
