import React from "react";
import styled, { css } from "styled-components";

// Services
import { navigate } from "../../services/navigation";

// Utils
import { Font, Color, responsive, rem } from "../../utils/style";
import { getPlanIdAndQuantityArray } from "../../utils/bundle";
import { trackCustomBundleAdded } from "../../utils/tracking/bundle";

// Components
import MagicModal from "../MagicModal";
import RitualButton from "../global/RitualButton";
import Text from "../Text";

// Store
import { connect } from "react-redux";
import { addProductsToCart } from "../../store/cart/actions";

const ModalStyle = css`
  padding: 40px 24px;

  ${responsive.sm`
    padding: 40px;
    max-width: 580px;
  `}

  ${responsive.md`
    padding: 56px 102px;
    max-width: 770px;
  `}

  ${responsive.lg`
    padding: 56px 100px;
  `}
`;

const Title = styled.h1`
  margin: 0;
  font-weight: 500;

  letter-spacing: -0.2px;
  font-size: ${rem(22)};
  line-height: ${rem(32)};

  ${responsive.md`
    letter-spacing: -0.4px;
    font-size: ${rem(30)};
    line-height: ${rem(36)};
  `}
`;

const Divider = styled.hr`
  border: none;
  height: 2px;
  background-color: ${Color.ritualBlue};
  width: 100%;

  margin: 24px 0 16px;

  ${responsive.md`
    margin: 40px 0 24px;
  `}
`;

const Message = styled.p`
  margin: 0;
  ${Font.dutch};

  font-size: ${rem(16)};
  line-height: ${rem(26)};
  margin-bottom: 32px;

  ${responsive.md`
    margin: 0 0 40px;
  `}
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${responsive.sm`
    flex-direction: row;
  `}
`;

const Button = styled(RitualButton)`
  a {
    padding: 0 32px;
  }
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }

  ${responsive.sm`
    margin-bottom: 0;
    margin-left: 24px;

    &:last-of-type {
      order: -1;
      margin-left: 0;
    }
  `}
`;

export class AbandonmentModal extends React.Component {
  constructor() {
    super();

    this.state = {
      closed: false,
    };
  }

  handleAbanadon() {
    this.setState(
      {
        closed: true,
      },
      () => {
        navigate(this.props.closePath);
      },
    );
  }

  handleAddToCart() {
    this.setState(
      {
        closed: true,
      },
      () => {
        const bundle = this.props.bundle;
        const planIds = bundle.map(item => item.id);
        const productsToAdd = getPlanIdAndQuantityArray(planIds);

        trackCustomBundleAdded(bundle);

        this.props.dispatchAddProductsToCart(productsToAdd);
        navigate("/cart");
      },
    );
  }

  render() {
    return (
      <MagicModal
        isOpen={this.props.isOpen && !this.state.closed}
        onRequestClose={this.props.onRequestClose}
        contentStyle={ModalStyle}
      >
        <Title>
          <Text
            id="build-bundle.abandonment-modal.title"
            defaultMessage="You’re almost there"
          />
        </Title>
        <Divider />
        <Message>
          <Text
            id="build-bundle.abandonment-modal.message"
            defaultMessage={`Are you sure you want to leave? Your recommendations are ready—would
          you like to add them to your cart before you go?`}
          />
        </Message>
        <ButtonsContainer>
          <Button onClick={this.handleAddToCart.bind(this)}>
            <Text
              id="build-bundle.abandonment-modal.add"
              defaultMessage={`Add to Cart`}
            />
          </Button>
          <Button className="bordered" onClick={this.handleAbanadon.bind(this)}>
            <Text
              id="build-bundle.abandonment-modal.abandon"
              defaultMessage={`Leave Without Saving`}
            />
          </Button>
        </ButtonsContainer>
      </MagicModal>
    );
  }
}

export default connect(null, {
  dispatchAddProductsToCart: addProductsToCart,
})(AbandonmentModal);
