import React from "react";
import styled from "styled-components";

// Utils
import { Color, Font, rem, responsive, rgba } from "../../utils/style";
import NameMap from "../../utils/nameMap";
import metrics from "../../utils/metrics";

// Components
import Img from "gatsby-image";
import SupplementFacts from "../product/SupplementFacts";
import QuantityPicker from "../global/QuantityPicker";
import Text from "../Text";

// Styled Elements
const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${responsive.sm`
    flex-direction: row;
  `}
`;

const Title = styled.h4`
  font-size: ${rem(14)};
  font-weight: 500;
  line-height: ${rem(24)};
  margin-bottom: 10px;

  em {
    ${Font.dutch};
  }

  ${responsive.sm`
    font-size: ${rem(20)};
    font-weight: 500;
    line-height: ${rem(30)};
    margin-bottom: 0;
  `}
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;

  ${responsive.sm`
    flex-direction: column;
    width: calc(100% - 144px);
  `}
`;

const ProductImage = styled.div`
  height: 56px;
  width: 56px;
  min-width: 56px;
  margin-right: 16px;
  margin-top: 2px;
  overflow: hidden;

  background-color: ${Color.ritualYellow};

  ${responsive.sm`
    height: 120px;
    width: 120px;
    min-width: 120px;
    margin-right: 24px;
    margin-top: 0;
  `}
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${responsive.sm`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 16px;
  `}
`;

const Description = styled.p`
  color: ${rgba(Color.ritualBlue, 0.56)};
  font-size: ${rem(12)};
  font-weight: 300;
  line-height: ${rem(18)};
  margin-bottom: 4px;
  text-align: left;

  ${responsive.sm`
    font-size: ${rem(14)};
    line-height: ${rem(20)};
    margin-bottom: 0;
  `}

  ${responsive.md`
    font-size: ${rem(16)};
    line-height: ${rem(22)};
  `}
`;

const SupplementFactsButton = styled.button`
  width: fit-content;
  color: ${Color.ritualBlue};
  font-size: ${rem(12)};
  font-weight: 500;
  letter-spacing: 0px;
  line-height: ${rem(18)};
  padding: 0;

  border: none;
  border-bottom: 2px solid ${Color.ritualBlue};
  background: none;

  transition: opacity 0.2s;

  &:hover {
    opacity: 0.56;
  }

  ${responsive.sm`
    font-size: ${rem(14)};
    line-height: ${rem(24)};
  `}

  ${responsive.md`
    font-size: ${rem(16)};
    line-height: ${rem(26)};
  `}
`;

export default class ProductResult extends React.Component {
  constructor(props) {
    super(props);

    this.factsPopup = React.createRef();
  }

  handlePopup() {
    const name = this.props.product.name.childMarkdownRemark.rawMarkdownBody;

    if (this.factsPopup) {
      this.factsPopup.current.open();

      metrics.track("Modal Viewed", {
        title: "Supplement Facts",
        location: "Product result",
        name,
      });
    }
  }

  onQuantityChange(e) {
    const quantity = Number(e.target.value);
    this.props.onQuantityChange(this.props.planId, quantity);
  }

  renderOption() {
    const {
      showSupplementFacts = true,
      showQuantityPicker = false,
      productQuantity,
      maxQuantity,
      totalQuantity,
      planId,
    } = this.props;

    return (
      <>
        {showSupplementFacts && (
          <SupplementFactsButton onClick={this.handlePopup.bind(this)}>
            <Text
              id="build-bundle.product-result.supplement-facts-button"
              defaultMessage="Supplement Facts"
            />
          </SupplementFactsButton>
        )}
        {!showSupplementFacts && showQuantityPicker && (
          <QuantityPicker
            itemId={planId}
            itemQuantity={productQuantity}
            totalQuantity={totalQuantity}
            maxQuantity={maxQuantity}
            onQuantityChange={this.onQuantityChange.bind(this)}
            allowZero={true}
            disabled={false}
          />
        )}
      </>
    );
  }

  render() {
    const { product } = this.props;

    const productName = NameMap({
      name: product.name.childMarkdownRemark.rawMarkdownBody,
    });

    const productImage = product?.heroFeaturedImages?.[0];

    return (
      <Container className="product-result-container">
        <div className="mobile-layout d-block d-sm-none">
          <Title dangerouslySetInnerHTML={{ __html: productName.html }} />
          <InfoContainer>
            <ProductImage>
              {productImage && (
                <Img
                  fluid={productImage.fluid}
                  loading="eager"
                  alt={productImage.title}
                  style={{
                    userSelect: "none",
                    userDrag: "none",
                    pointerEvents: "none",
                    touchCallout: "none",
                  }}
                />
              )}
            </ProductImage>
            <DescriptionContainer>
              <Description>{product.headerDescription}</Description>
              {this.renderOption()}
            </DescriptionContainer>
          </InfoContainer>
        </div>

        <div className="desktop-layout d-none d-sm-flex w-100">
          <ProductImage>
            {productImage && (
              <Img
                fluid={productImage.fluid}
                loading="eager"
                alt={productImage.description}
                style={{
                  userSelect: "none",
                  userDrag: "none",
                  pointerEvents: "none",
                  touchCallout: "none",
                }}
              />
            )}
          </ProductImage>
          <InfoContainer>
            <DescriptionContainer>
              <Title dangerouslySetInnerHTML={{ __html: productName.html }} />
              {this.renderOption()}
            </DescriptionContainer>
            <Description>{product.headerDescription}</Description>
          </InfoContainer>
        </div>
        <SupplementFacts
          supplementFacts={product.supplementFacts}
          ref={this.factsPopup}
        />
      </Container>
    );
  }
}
