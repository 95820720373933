import React from "react";
import styled from "styled-components";

// Utils
import { Color, rem, responsive } from "../../utils/style";

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;

  input {
    width: 100%;
    padding: 0;
    margin: 0;
    border-radius: 16px;
    overflow: hidden;
    border: none;
    -webkit-appearance: none !important;
    -moz-appearance: textfield;

    [data-whatintent="mouse"] &:focus,
    [data-whatintent="touch"] &:focus {
      outline: none;
    }

    font-size: ${rem(22)};
    line-height: ${rem(32)};
    letter-spacing: -0.2px;
    color: ${Color.ritualBlue};

    padding: 16px 78px 16px 32px;

    ${responsive.sm`
      font-size: ${rem(30)};
      line-height: ${rem(36)};
      letter-spacing: -0.4px;
      padding: 24px 110px 24px 48px;
    `};

    ::placeholder {
      color: rgba(20, 43, 111, 0.24);
    }

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
`;

export default class QuizInput extends React.Component {
  handleChange(e) {
    const { length = 50 } = this.props;
    let value = e.target.value;
    value = value.substring(0, length);

    this.props.onChange(value);
  }

  render() {
    const {
      className,
      value,
      type = "number",
      inputMode = "numeric",
      placeholder,
      min = 0,
      max = 150,
      step = 1,
    } = this.props;

    return (
      <InputWrapper className={className}>
        <input
          value={value || ""}
          inputMode={inputMode}
          type={type}
          min={min}
          max={max}
          step={step}
          onChange={this.handleChange.bind(this)}
          placeholder={placeholder}
        />
      </InputWrapper>
    );
  }
}
