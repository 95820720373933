import React from "react";
import { css } from "styled-components";

// Components
import Ribbon from "../global/Ribbon";

// Utils
import { rem, responsive } from "../../utils/style";

// Styled Elementscss
const QuizRibbonStyle = css`
  margin-bottom: 24px;

  height: 28px;
  padding: 0 11px;
  letter-spacing: 0.67px;
  font-size: ${rem(10)};
  line-height: ${rem(16)};

  &::before,
  &::after {
    border-width: 14px 6px;
  }

  ${responsive.sm`
    height: 32px;

    letter-spacing: 0.8px;
    font-size: ${rem(12)};
    line-height: ${rem(20)};

    &::before,
    &::after {
      border-width: 16px 8px;
    }
  `}
`;

export default class QuizRibbon extends React.Component {
  render() {
    let { children } = this.props;
    return (
      <Ribbon contentStyle={QuizRibbonStyle} twoSided={true}>
        {children}
      </Ribbon>
    );
  }
}
