import React from "react";

// Services
import { navigate } from "../../services/navigation";
import intl from "../../services/intl";

// Utils
import metrics from "../../utils/metrics";

// Components
import Navigation from "./Navigation";
import SlideContainer from "./SlideContainer";
import Disclaimer from "./Disclaimer";
import AbandonmentModal from "./AbandonmentModal";

// Slide Components
import Intro from "./slides/Intro";
import TakeVitamins from "./slides/TakeVitamins";
import ProductSelectionSlide from "./slides/ProductSelectionSlide";
import AgeGenderSlide from "./slides/AgeGender";
import Intermission from "./slides/Intermission";
import ValuePropsIntermission from "./slides/ValuePropsIntermission";
import Results from "./slides/Results";
import HouseholdBuilderSlide from "./slides/HouseholdBuilderSlide";

// Data
import slideData from "../../data/bundle-builder/slides";

// Map of slide components
const components = {
  IntroSlide: Intro,
  TakeVitamins: TakeVitamins,
  AgeGenderSlide: AgeGenderSlide,
  PrenatalSlide: ProductSelectionSlide,
  PostmenopausalSlide: ProductSelectionSlide,
  IntermissionSlide: Intermission,
  Results: Results,
  HouseholdBuilderSlide: HouseholdBuilderSlide,
  ValuePropsIntermission: ValuePropsIntermission,
};

export default class BundleLoopFlow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      abandonmentModalClosePath: null,
      showDisclaimer: true,
      transition: false,
      // Slide Logic State
      slides: slideData?.v1Kids,
      currentSlide: slideData?.v1Kids?.[0],
      slideHistory: [],
      /* User Input
      First item in the array is always the initial user,
      every item following the first item is every user added
      from the Household Builder slide
      */
      userInput: [
        {
          age: undefined,
          gender: undefined,
          product: undefined,
        },
      ],
      // Results
      resultsBundle: [],
    };

    this.timeout = null;
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  setDisclaimerState(shouldShow) {
    this.setState({
      showDisclaimer: shouldShow,
    });
  }

  updateResultsBundle(bundle) {
    this.setState({
      resultsBundle: bundle,
    });
  }

  // Slide Logic
  nextSlide(answer) {
    const { userInput, currentSlide } = this.state;
    // Handles which slide to show next
    let nextSlide = answer.nextSlide.firstRun;

    // If answer has SKU associated, we add the product
    // to newly created bundle. The Results slide will handle
    // displaying array of products in the bundle.
    if (answer.sku) {
      this._handleSlideSku(answer.sku);
    }

    // If a QuizButton is clicked (not a QuizAlternateButton) we trigger this
    // CTA.
    if (answer.copy) {
      metrics.track("Quiz CTA Clicked", {
        title: intl.t(answer.copy),
        location: currentSlide.slideName,
      });
    }

    // Handles changing currently viewed slide
    this._goToSlide(nextSlide);
  }

  _handleSlideSku(sku) {
    // If single SKU as string
    if (typeof sku === "string") {
      this.onProductChange(sku, 0);
    }
  }

  _goToSlide(nextSlideName) {
    // Get slide object based on slideName
    const slide = this.state.slides.find(
      slide => slide.slideName === nextSlideName,
    );

    // Add slideName to list of slide history
    let slideHistory = [...this.state.slideHistory];
    slideHistory.push(this.state.currentSlide.slideName);

    window.scrollTo(0, 0);
    this._transition({
      currentSlide: slide,
      slideHistory,
    });
  }

  _transition(state) {
    this.setState({
      transition: true,
    });

    this.timeout = setTimeout(() => {
      this.setState({
        transition: false,
        ...state,
      });
    }, 600);
  }

  _getCompletionPercent() {
    const { slides, currentSlide } = this.state;

    // Give the 1-based index of our current slide and the first results slide.
    const index =
      slides.findIndex(s => s.slideName === currentSlide.slideName) + 1;
    const firstResultsIndex =
      slides.findIndex(s => s.slideName === "results") + 1;

    // If it's first slide, or the currentSlide does not existing in the array
    // (should not happen), return 0.
    if (index === 1) return 0;

    // Determines how far we've progressed through the first section of the
    // builder by checking our current index again the index of the first
    // results slide. If this index is equal to (or great than) the index of
    // the results slide, we know we've completed the first half of the quiz.
    const firstSectionLength = firstResultsIndex;
    const firstSectionPercent = Math.min(index / firstSectionLength, 1);

    // Determines how far we've progressed through the second section of the
    // builder by checking how many steps past the first results slide we've
    // moved against how many total slides exist past the first results slide.
    const offsetIndex = index - firstResultsIndex;
    const secondSectionLength = slides.length - firstResultsIndex;
    const secondSectionPercent = Math.max(offsetIndex / secondSectionLength, 0);

    // The first half and the second half should each be treated as 50% of the
    // builder progress.
    return (firstSectionPercent * 0.5 + secondSectionPercent * 0.5) * 100;
  }

  goToPreviousSlide() {
    const slideHistory = this.state.slideHistory;

    // Get last slide and remove from history
    const lastSlideName = slideHistory.pop();
    const lastSlide = this.state.slides.find(
      slide => slide.slideName === lastSlideName,
    );

    this._transition({
      currentSlide: lastSlide,
      slideHistory,
    });
  }

  addHouseholdMember() {
    // Adds new member to household, undefined values ready for user input
    let userInput = [...this.state.userInput];
    userInput.push({
      age: undefined,
      gender: undefined,
      product: undefined,
    });

    this.setState({
      userInput,
    });
  }

  removeHouseholdMember(index) {
    let userInput = [...this.state.userInput];
    userInput.splice(index, 1);

    this.setState({
      userInput,
    });
  }

  onAgeChange(inputAge, index = 0) {
    this._onChange(index, "age", inputAge);
  }

  onGenderChange(inputGender, index = 0) {
    this._onChange(index, "gender", inputGender);
  }

  onProductChange(inputProduct, index = 0) {
    this._onChange(index, "product", inputProduct);
  }

  _onChange(index, field, value) {
    let userInput = [...this.state.userInput];
    userInput[index][field] = value;

    this.setState({
      userInput,
    });
  }

  onClose(path, e) {
    e.preventDefault();

    if (!this._hasUnsavedChanges()) {
      navigate(path);
      return;
    }

    this.setState({
      abandonmentModalClosePath: path,
    });
  }

  _hasUnsavedChanges() {
    return !!this.state.resultsBundle.length;
  }

  onModalClose() {
    this.setState({
      abandonmentModalClosePath: null,
    });
  }

  render() {
    const {
      abandonmentModalClosePath,
      showDisclaimer,
      // Slide State
      currentSlide,
      slides,
      transition,
      // User input
      userInput,
      // Results
      resultsBundle,
    } = this.state;
    const { data, discount } = this.props;

    if (!currentSlide || !slides) return null;

    const completionPercent = this._getCompletionPercent();

    // Select slide component to show based on currentSlide `componentName` value
    const SelectedComponent = components[currentSlide.componentName];

    const slideProps = {
      // Slide State
      slide: currentSlide,
      nextSlide: this.nextSlide.bind(this),
      // User input
      userInput,
      age: userInput[0].age,
      gender: userInput[0].gender,
      onAgeChange: this.onAgeChange.bind(this),
      onGenderChange: this.onGenderChange.bind(this),
      onProductChange: this.onProductChange.bind(this),
      addHouseholdMember: this.addHouseholdMember.bind(this),
      removeHouseholdMember: this.removeHouseholdMember.bind(this),
      updateResultsBundle: this.updateResultsBundle.bind(this),
      // Bundle details
      data,
      discount,
    };

    return (
      <>
        <Navigation
          currentSlide={currentSlide}
          goToPreviousSlide={this.goToPreviousSlide.bind(this)}
          onClose={this.onClose.bind(this)}
        />
        <SlideContainer
          transition={transition}
          completionPercent={completionPercent}
          backgroundImage={data.backgroundImage}
        >
          <SelectedComponent {...slideProps} />
        </SlideContainer>
        {showDisclaimer && <Disclaimer />}
        <AbandonmentModal
          bundle={resultsBundle}
          isOpen={!!abandonmentModalClosePath}
          closePath={abandonmentModalClosePath}
          onRequestClose={this.onModalClose.bind(this)}
        />
      </>
    );
  }
}
