import React from "react";
import styled from "styled-components";

// Utils
import { Color, rem, responsive } from "../../../utils/style";
import metrics from "../../../utils/metrics";
import intl from "../../../services/intl";

// Components
import QuizAlternateButton from "../QuizAlternateButton";
import QuizDropdown from "../QuizDropdown";
import QuizInput from "../QuizInput";
import YellowArrow from "../YellowArrow";
import NonBinaryLink from "../NonBinaryLink";
import NoProductMessage from "../NoProductMessage";
import Text from "../../Text";

// Styled Elements
const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 56px 24px;

  ${responsive.sm`
    padding: 80px 24px;
  `};
`;

const Title = styled.h1`
  max-width: 150px;
  text-align: center !important;
  color: ${Color.ritualBlue};
  font-weight: 500;
  font-size: ${rem(26)};
  line-height: ${rem(36)};
  letter-spacing: -0.3px;
  margin: 0 0 24px;

  ${responsive.sm`
    max-width: 300px;
    font-size: ${rem(48)};
    line-height: ${rem(54)};
    letter-spacing: -1.4px;
    margin-bottom: 40px;
  `};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  ${responsive.sm`
    max-width: 370px;
  `};
`;

const ArrowWrapper = styled.div`
  position: relative;
`;

const Arrow = styled(YellowArrow)`
  position: absolute;
  left: -20px;
  bottom: 6px;
  transform: rotate(60deg);
`;

const GenderDropdown = styled(QuizDropdown)`
  margin-bottom: 8px;

  ${responsive.sm`
    margin-bottom: 16px;
  `}
`;

const AgeInput = styled(QuizInput)`
  margin-bottom: 32px;

  ${responsive.sm`
    margin-bottom: 40px;
  `}
`;

export default class Age extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: true,
      under: false,
      over: false,
    };

    this.genderOptions = [
      {
        title: intl.t("build-bundle.slides.age-gender.sex", "Sex"),
        value: intl.t("build-bundle.slides.age-gender.sex", "Sex"),
        disabled: true,
      },
      {
        title: intl.t("build-bundle.slides.age-gender.female", "Female"),
        value: intl.t("build-bundle.slides.age-gender.female", "Female"),
      },
      {
        title: intl.t("build-bundle.slides.age-gender.male", "Male"),
        value: intl.t("build-bundle.slides.age-gender.male", "Male"),
      },
    ];

    this.debounce = null;
    this.timeout = null;
    this.handleChangeAge = this.handleChangeAge.bind(this);
    this.handleChangeGender = this.handleChangeGender.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.checkState(null, this.props.age);
  }

  componentWillUnmount() {
    if (this.debounce) {
      clearTimeout(this.debounce);
    }
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  handleChangeAge(age) {
    if (this.debounce) {
      clearTimeout(this.debounce);
    }

    age = Number(age);

    this.checkState(null, age);
    this.props.onAgeChange && this.props.onAgeChange(age);
  }

  handleChangeGender(gender) {
    this.checkState(gender);
    this.props.onGenderChange(gender);
  }

  checkState(gender, age) {
    gender = gender || this.props.gender;
    age = age || this.props.age;

    const underAgeThreshold = 13;

    const disabled = !gender || !age || age < underAgeThreshold || age > 100;

    this.setState({
      disabled,
      under: false,
      over: false,
    });

    // Immediately disable the input, but delay showing the over/under messages.
    this.debounce = setTimeout(() => {
      this.setState({
        under: age && age !== "" && age < underAgeThreshold,
        over: age && age !== "" && age > 100,
      });
    }, 800);
  }

  handleSubmit(e) {
    e.preventDefault();

    const { age, gender, slide } = this.props;

    if (!age || !gender) return;

    const answerGroup = slide.answers[gender];
    let answer = answerGroup.under50;

    if (age >= 50) {
      answer = answerGroup.over50;
    }

    metrics.track("Quiz Personal Info Entered", {
      location: slide.slideName,
      gender,
      age,
    });

    metrics.track("Quiz CTA Clicked", {
      title: "Continue",
      location: slide.slideName,
      gender,
      age,
    });

    this.props.nextSlide(answer);
  }

  render() {
    const { disabled, over, under } = this.state;
    const { age, gender } = this.props;

    const currentGenderOption = {
      title: gender || undefined,
      value: gender || undefined,
    };

    return (
      <Wrapper>
        <ArrowWrapper>
          <Title>
            <Text
              id="build-bundle.slides.age-gender.title"
              defaultMessage="Tell us about yourself"
            />
          </Title>
          <Arrow />
        </ArrowWrapper>
        <Form onSubmit={this.handleSubmit}>
          <GenderDropdown
            currentOption={currentGenderOption}
            options={this.genderOptions}
            onSelectionMade={this.handleChangeGender}
          />
          <AgeInput
            value={age || ""}
            onChange={this.handleChangeAge}
            placeholder={intl.t(
              "build-bundle.slides.age-gender.age-placeholder",
              "Age",
            )}
            length={3}
          />
          <QuizAlternateButton type="submit" disabled={disabled}>
            <Text
              id="build-bundle.slides.age-gender.submit"
              defaultMessage="Continue"
            />
          </QuizAlternateButton>
        </Form>

        <NoProductMessage
          className="mb-2"
          firstRun={true}
          over={over}
          under={under}
        />

        <NonBinaryLink />
      </Wrapper>
    );
  }
}
