import React from "react";
import styled from "styled-components";

// Utils
import { Color, rem, Font, responsive, rgba } from "../../../utils/style";
import { getDiscountData } from "../../../utils/bundle";

// Components
import QuizRibbon from "../QuizRibbon";
import QuizButton from "../QuizButton";
import YellowArrow from "../YellowArrow";
import Text from "../../Text";

// Styled Elements
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 48px 24px 38px;

  ${responsive.sm`
    max-width: 500px;
    margin: 80px auto;
  `};

  ${responsive.md`
    max-width: 500px;
    margin: 80px auto;
  `};

  ${responsive.lg`
    margin: 120px auto;
  `}
`;

const Copy = styled.div`
  margin-bottom: 32px;

  h1 {
    font-size: ${rem(22)};
    letter-spacing: -0.2px;
    line-height: ${rem(32)};
    text-align: center;
    margin-bottom: 16px;
  }

  p {
    ${Font.dutch}
    font-size: ${rem(16)};
    letter-spacing: 0px;
    line-height: ${rem(26)};
    font-weight: normal;
    text-align: center;
    margin-bottom: 0;
  }

  ${responsive.sm`
    margin-bottom: 48px;
    h1 {
      font-size: ${rem(40)};
      letter-spacing: -1px;
      line-height: ${rem(50)};
      margin-bottom: 24px;
    }

    p {
      font-size: ${rem(18)};
      line-height: ${rem(28)};
      width: 500px;
    }

  `}
`;

const TagLine = styled.p`
  color: ${rgba(Color.ritualBlue, 0.56)};
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  text-align: center;
  margin-top: 40px;
  margin-bottom: 0;

  ${responsive.sm`
    margin-top: 48px;
    font-size: ${rem(18)};
    line-height: ${rem(28)};
  `}
`;

const IntroQuizButton = styled(QuizButton)`
  width: 100%;

  ${responsive.sm`
    width: auto;
  `};
`;

const ArrowWrapper = styled.div`
  position: relative;
  width: 100%;

  ${responsive.sm`
    width: auto;
  `};
`;

const Arrow = styled(YellowArrow)`
  position: absolute;
  right: 9px;
  top: -25px;
  transform: scaleX(-1) rotate(-25deg);

  ${responsive.sm`
    right: -70px;
    top: -29px;
    transform: scaleX(-1) rotate(-20deg);
  `};
`;

export default class Intro extends React.Component {
  render() {
    const { discount, nextSlide, slide } = this.props;
    const discountData = getDiscountData();
    return (
      <Wrapper>
        {discount && (
          <QuizRibbon>
            <Text
              id="build-bundle.slides.intro.discount"
              defaultMessage="Bundle and save {amount}"
              values={{
                amount: discountData.sentenceCopy,
              }}
            />
          </QuizRibbon>
        )}
        <Copy>
          <h1>
            <Text
              id={slide.componentContent.title}
              defaultMessage="First, let’s get to know each other a little better."
            />
          </h1>
          <p>
            <Text
              id={slide.componentContent.copy}
              defaultMessage="A healthy home can take many forms. Our scientists and nutritional experts reviewed thousands of independent research studies, looking at dietary intake, lifestyle, and genetics, to design a Ritual that’s right for yours.*"
            />
          </p>
        </Copy>
        <ArrowWrapper>
          <IntroQuizButton
            clickHandler={() => {
              nextSlide(slide.answers[0]);
            }}
          >
            <Text id={slide.answers[0].copy} defaultMessage="Let's Go" />
          </IntroQuizButton>
          <Arrow />
        </ArrowWrapper>

        <TagLine>
          <Text
            id="build-bundle.slides.intro.tagline"
            defaultMessage="This will only take a few minutes."
          />
        </TagLine>
      </Wrapper>
    );
  }
}
