import React from "react";
import styled from "styled-components";

// Services
import intl from "../../../services/intl";

// Utils
import { Color, rem, responsive } from "../../../utils/style";
import metrics from "../../../utils/metrics";

// Components
import YellowArrow from "../YellowArrow";
import QuizDropdown from "../QuizDropdown";
import QuizAlternateButton from "../QuizAlternateButton";
import Text from "../../Text";

// Styled Elements
const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 56px 24px 0;

  ${responsive.sm`
    padding-top: 80px;
  `};
`;

const Title = styled.h1`
  color: ${Color.ritualBlue};
  font-weight: 500;
  font-size: ${rem(26)};
  line-height: ${rem(36)};
  letter-spacing: -0.3px;
  margin: 0 0 24px;
  text-align: center !important;
  max-width: 220px;

  ${responsive.sm`
    font-size: ${rem(48)};
    line-height: ${rem(54)};
    letter-spacing: -1.4px;
    margin-bottom: 40px;
    max-width: 430px;
  `};
`;

const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  ${responsive.sm`
    width: 370px;
  `};
`;

const ArrowWrapper = styled.div`
  position: relative;
`;

const Arrow = styled(YellowArrow)`
  position: absolute;
  right: -9px;
  bottom: 8px;
  transform: scaleX(-1) rotate(54deg);

  ${responsive.sm`
    right: -23px;
    bottom: -16px;
  `};
`;

export default class Prenatal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOption: null,
    };

    this.handleClick = this.handleClick.bind(this);
    this.onSelectionMade = this.onSelectionMade.bind(this);
  }

  onSelectionMade(value) {
    this.setState({
      selectedOption: value,
    });
  }

  handleClick() {
    const { selectedOption } = this.state;
    const { slide } = this.props;

    if (!selectedOption) return;

    let answer = slide.answers[selectedOption - 1];

    metrics.track("Quiz CTA Clicked", {
      title: "Continue",
      location: slide.slideName,
      interest: intl.t(answer.copy),
    });

    // Clone the answer and delete the copy field to ensure the
    // "Quiz CTA Clicked" event is not reported twice.
    answer = { ...answer };
    delete answer.copy;

    this.props.nextSlide(answer);
  }

  render() {
    const { selectedOption } = this.state;
    const disabled = !selectedOption;

    const options = [
      {
        title: intl.t(
          "build-bundle.product-select.option-placeholder",
          "Interested in",
        ),
        value: "placeholder",
        disabled: true,
      },
    ];

    this.props.slide.answers.forEach((answer, i) => {
      options.push({
        title: intl.t(answer.copy),
        value: i + 1,
      });
    });

    const currentOption = selectedOption ? options[selectedOption] : options[0];

    return (
      <Wrapper>
        <ArrowWrapper>
          <Title>
            <Text
              id="build-bundle.product-select.title"
              defaultMessage="What are you looking for?"
            />
          </Title>
          <Arrow />
        </ArrowWrapper>
        <ButtonWrapper>
          <QuizDropdown
            smallOptions={true}
            smallPlaceholder={!!selectedOption}
            options={options}
            currentOption={currentOption}
            onSelectionMade={this.onSelectionMade}
            className="mb-4--5 mb-sm-5"
          />
          <QuizAlternateButton disabled={disabled} onClick={this.handleClick}>
            <Text
              id="build-bundle.product-select.quiz-alternate"
              defaultMessage="Continue"
            />
          </QuizAlternateButton>
        </ButtonWrapper>
      </Wrapper>
    );
  }
}
