import React from "react";
import styled from "styled-components";

import { Color, rem, responsive } from "../../utils/style";
import { Icons } from "../../utils/svg";
import Text from "../Text";

const Quantity = styled.div`
  display: flex;
  align-items: center;
`;

const SelectWrapper = styled.div`
  position: relative;
  align-items: center;
  color: ${Color.ritualBlue};
  font-weight: 500;
  font-size: ${rem(16)};
  line-height: ${rem(26)};

  ${responsive.md`
    font-size: ${rem(14)};
    line-height: ${rem(24)};
  `}

  svg {
    display: none;

    ${responsive.md`
      display: block;
      width: 14px;
      height: 8px;
      position: absolute;
      top: calc(50% - 4px);
      right: 0;
      pointer-events: none;
    `};
  }

  > span {
    position: absolute;
    left: 0;
    pointer-events: none;
  }

  &.disabled {
    opacity: 0.24;
  }

  .sr-label {
    display: none;
  }
`;

const StyledLabel = styled.label`
  position: relative;
  font-size: ${rem(12)};
  line-height: ${rem(18)};
  color: ${Color.ritualBlue};
  border-bottom: 2px solid ${Color.ritualBlue};
  margin: 0;
  padding: 0;
`;

const Select = styled.select`
  position: absolute;
  font-size: ${rem(16)};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  border: none;
  border-radius: 0px !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  margin: 0;
  background: 0;
  width: 100%;

  ${responsive.md`
    width: auto;
    position: relative;
    opacity: 1;
    padding: 0 0 0 35px;
    font-size: inherit;
    color: ${Color.ritualBlue};
    cursor: pointer;
    text-align: right !important;
    border-bottom: 2px solid ${Color.ritualBlue};
    border-bottom: none;
    padding: 0 15px 0 30px;
    width: calc(15px + 15px + 30px);
    text-align: left;
  `};

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }
`;

export default class QuantityPicker extends React.Component {
  renderSelect(label = false) {
    const {
      itemId,
      itemQuantity,
      totalQuantity,
      maxQuantity,
      disabled,
      allowZero,
    } = this.props;

    // Numbers of spaces currently available.
    let availableSpace = maxQuantity - totalQuantity;
    let hasOtherItems = totalQuantity !== itemQuantity;

    if (availableSpace < 1) {
      // Default to current quantity if there's no room for more items.
      availableSpace = itemQuantity;
    } else {
      availableSpace += itemQuantity;
    }

    return (
      <Select
        aria-labelledby={label ? label : undefined}
        id={itemId}
        value={itemQuantity}
        onChange={this.props.onQuantityChange}
        disabled={disabled}
      >
        <option disabled>Quantity</option>
        {allowZero && hasOtherItems && <option value={0}>0</option>}
        {[...Array(availableSpace)].map((_, i) => {
          const amount = i + 1;
          return (
            <option key={i} value={amount}>
              {amount}
            </option>
          );
        })}
      </Select>
    );
  }

  render() {
    const { itemId, itemQuantity, maxQuantity } = this.props;

    if (maxQuantity === 1) {
      return (
        <Quantity>
          <SelectWrapper>
            <Text
              id="qty-picker.label-qty"
              defaultMessage="Qty {itemQuantity}"
              values={{ itemQuantity }}
            />
          </SelectWrapper>
        </Quantity>
      );
    }

    const labelId = `${itemId}-label`;

    return (
      <Quantity>
        <SelectWrapper className="d-flex d-md-none">
          <StyledLabel
            id={`${labelId}-mobile`}
            aria-label="Quantity"
            htmlFor={itemId}
          >
            <Text
              id="qty-picker.label-qty"
              defaultMessage="Qty {itemQuantity}"
              values={{ itemQuantity }}
            />
            <Icons.CaretDown />
            {this.renderSelect(labelId)}
          </StyledLabel>
        </SelectWrapper>
        <SelectWrapper className="d-none d-md-flex">
          <span>
            <Text id="qty-picker.label" defaultMessage="Qty" />
          </span>
          {this.renderSelect()}
          <Icons.CaretDown />
        </SelectWrapper>
      </Quantity>
    );
  }
}
