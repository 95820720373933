import React from "react";
import styled from "styled-components";

// Utils
import { Color, rem } from "../../utils/style";

// Styled Elements
const Button = styled.button`
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border: none;
  transition: all 0.2s;

  width: 212px;
  height: 50px;
  padding: 0 24px;
  margin: 0;
  border-radius: 26px;
  background-color: ${Color.ritualBlue};

  color: ${Color.white};
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  font-weight: 500;

  &:hover {
    background-color: ${Color.ritualYellow};
    color: ${Color.ritualBlue};
  }

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }

  &.disabled {
    opacity: 0.56;

    &:hover {
      background-color: ${Color.ritualBlue};
      color: ${Color.white};
    }
  }
`;

export default class QuizButton extends React.Component {
  handleClick() {
    if (document && document.documentElement) {
      document.documentElement.scrollTop = 0;
    }
    this.props.onClick && this.props.onClick();
  }

  render() {
    let { children, className, disabled } = this.props;

    return (
      <Button
        className={className}
        disabled={disabled}
        onClick={this.handleClick.bind(this)}
      >
        {children}
      </Button>
    );
  }
}
