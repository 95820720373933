import React from "react";

// Utils
import { Icons } from "../../utils/svg";

export default class YellowArrow extends React.Component {
  render() {
    const { className } = this.props;
    return (
      <div className={className}>
        <Icons.ArrowCurvedLg className="d-none d-sm-block" />
        <Icons.ArrowCurvedSm className="d-block d-sm-none" />
      </div>
    );
  }
}
