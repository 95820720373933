import React from "react";
import styled from "styled-components";
import { graphql, StaticQuery } from "gatsby";

// Services
import intl from "../../../services/intl";

// Utils
import { Color, rem, responsive, rgba } from "../../../utils/style";
import { Icons } from "../../../utils/svg";

// Components
import Img from "gatsby-image/withIEPolyfill";
import QuizButton from "../QuizButton";
import Text from "../../Text";

// Styled Elements
const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 66px);

  ${responsive.sm`
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    min-height: calc(100vh - 68px - 24px);
  `};

  ${responsive.md`
    min-height: calc(100vh - 84px - 24px);
  `};

  ${responsive.lg`
    min-height: calc(100vh - 84px - 40px);
  `};
`;

const SlideContentWrapper = styled.div`
  display: flex;
  justify-content: flex-start;

  ${responsive.sm`
    flex: 0 0 50%;
  `}

  ${responsive.md`
    justify-content: center;
  `}
`;

const SlideContent = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 56px 24px;
  height: calc(100% - 238px);

  ${responsive.sm`
    height: 100%;
    min-height: calc(100vh - 60px - 24px);
    justify-content: center;
    align-items: flex-start;
    padding: 80px 40px;
  `};

  ${responsive.md`
    max-width: 454px;
    min-height: calc(100vh - 76px - 24px);
  `};

  ${responsive.lg`
    min-height: calc(100vh - 76px - 40px);
  `};
`;

const SmallHeader = styled.p`
  color: rgba(20, 43, 111, 0.56);
  font-weight: 500;
  font-size: ${rem(12)};
  line-height: ${rem(20)};
  letter-spacing: 0.8px;
  text-transform: uppercase;
  text-align: center !important;
  margin: 0 0 8px;

  ${responsive.sm`
    font-size: ${rem(14)};
    line-height: ${rem(22)};
    letter-spacing: 0.88px;
    text-align: left !important;
    margin-bottom: 16px;
  `};

  ${responsive.md`
    font-size: ${rem(16)};
    line-height: ${rem(26)};
    letter-spacing: 1px;
  `};
`;

const Title = styled.h1`
  color: ${Color.ritualBlue};
  font-weight: 500;
  font-size: ${rem(20)};
  line-height: ${rem(30)};
  text-align: center !important;
  margin: 0 0 24px;

  ${responsive.sm`
    font-size: ${rem(30)};
    line-height: ${rem(36)};
    letter-spacing: -0.4px;
    text-align: left !important;
    margin-bottom: 24px;
  `};

  ${responsive.md`
    font-size: ${rem(34)};
    line-height: ${rem(40)};
    letter-spacing: -0.5px;
    margin-bottom: 32px;
  `};
`;

const Button = styled(QuizButton)`
  width: 100% !important;

  ${responsive.sm`
    width: auto !important;
  `};
`;

const ValueProps = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 300px;

  ${responsive.sm`
    max-width: unset;
    align-items: flex-start;
  `}
`;

const ValueProp = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 22px;

  &:last-of-type {
    margin-bottom: 32px;
  }

  ${responsive.sm`
    margin-bottom: 24px;

    &:last-of-type {
      margin-bottom: 40px;
    }
  `}

  .svg-wrapper {
    height: 32px;
    width: 32px;
    position: relative;
    margin-right: 16px;
    flex: 1 0 auto;

    svg {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1 1 auto;

    h3 {
      font-size: ${rem(16)};
      line-height: ${rem(26)};
      margin: 2px 0 4px;
    }

    p {
      color: ${rgba(Color.ritualBlue, 0.56)};
      font-size: ${rem(14)};
      font-weight: 300;
      line-height: ${rem(20)};
      margin-bottom: 0;
    }

    ${responsive.sm`
      h3 {
        font-size: ${rem(18)};
        line-height: ${rem(28)};
        margin: 0 0 4px;
      }

      p {
        font-size: ${rem(16)};
        line-height: ${rem(22)};
      }
    `}
  }
`;

const MobileImageWrapper = styled.div`
  position: relative;
  height: 244px;
  width: 100%;
`;

const DesktopImageWrapper = styled.div`
  flex: 0 0 50%;
`;

const productsImageStyle = {
  position: "absolute",
  zIndex: "0",
  userSelect: "none",
  userDrag: "none",
  pointerEvents: "none",
  touchCallout: "none",
};

const productsMobileImageStyle = {
  ...productsImageStyle,
  right: 0,
  top: 0,
  left: 0,
  width: "100%",
};

const productsDesktopImageStyle = {
  ...productsImageStyle,
  top: "50%",
  transform: "translateY(-50%)",
  left: 0,
  width: "50%",
};

export const ValuePropsIntermissionComponent = class ValuePropsIntermission extends React.Component {
  handleClick() {
    const { slide, nextSlide } = this.props;
    const answer = slide.answers[0];

    nextSlide(answer);
  }

  renderValueProp(valueProp) {
    let Icon = Icons[valueProp.svg];
    return (
      <ValueProp key={valueProp.svg}>
        <div className="svg-wrapper">
          <Icon />
        </div>
        <div className="text-wrapper">
          <h3>
            <Text id={valueProp.heading} />
          </h3>
          <p>
            <Text id={valueProp.subheading} />
          </p>
        </div>
      </ValueProp>
    );
  }

  render() {
    const { slide, contentfulData } = this.props;
    const { smallHeader, title, valueProps } = slide.componentContent;

    const productsImageMobile = intl.cf(
      contentfulData.productsMobileImages.nodes,
      true,
    );
    const productsImageDesktop = intl.cf(
      contentfulData.productsDesktopImages.nodes,
      true,
    );

    const answer = slide.answers[0];

    return (
      <Wrapper>
        <DesktopImageWrapper className="d-none d-sm-block">
          <Img
            className="d-none d-sm-block"
            fluid={productsImageDesktop.fluid}
            loading="eager"
            alt={productsImageDesktop.description}
            style={productsDesktopImageStyle}
          />
        </DesktopImageWrapper>
        <SlideContentWrapper>
          <SlideContent>
            <SmallHeader>
              <Text id={smallHeader} />
            </SmallHeader>
            <Title>
              <Text id={title} />
            </Title>
            <ValueProps>
              {valueProps.map(valueProp => {
                return this.renderValueProp(valueProp);
              })}
            </ValueProps>
            <Button clickHandler={this.handleClick.bind(this)}>
              <Text id={answer.copy} />
            </Button>
            <MobileImageWrapper className="d-block d-sm-none">
              <Img
                className="d-block d-sm-none"
                fluid={productsImageMobile.fluid}
                loading="eager"
                alt={productsImageMobile.description}
                style={productsMobileImageStyle}
              />
            </MobileImageWrapper>
          </SlideContent>
        </SlideContentWrapper>
      </Wrapper>
    );
  }
};

export default props => (
  <StaticQuery
    query={componentQuery}
    render={data => (
      <ValuePropsIntermissionComponent {...props} contentfulData={data} />
    )}
  />
);

// GraphQL
const componentQuery = graphql`
  query ValuePropsIntermissionComponentQuery {
    productsMobileImages: allContentfulAsset(
      filter: { contentful_id: { eq: "01LkK17xCCWIRDXVhorPZA" } }
    ) {
      nodes {
        node_locale
        description
        fluid(maxWidth: 304, quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    productsDesktopImages: allContentfulAsset(
      filter: { contentful_id: { eq: "zDJLLVtyioMaIanMitVfn" } }
    ) {
      nodes {
        node_locale
        description
        fluid(maxWidth: 643, quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  }
`;
