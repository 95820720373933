import React from "react";
import styled from "styled-components";

import { Color, responsive, rem } from "../../utils/style";
import MagicLink from "../MagicLink";
import Text from "../Text";

const Link = styled(MagicLink)`
  font-size: ${rem(14)};
  line-height: ${rem(24)};
  font-weight: 500;
  color: rgba(20, 43, 111, 0.56);
  border-bottom: 2px solid rgba(20, 43, 111, 0.56);
  margin: 32px auto 0;
  transition: all 0.2s;

  ${responsive.sm`
    font-size: ${rem(16)};
    line-height: ${rem(26)};
    margin-top: 40px;
  `};

  &:hover {
    color: ${Color.ritualBlue};
    border-color: ${Color.ritualBlue};
  }
`;

export default class NonBinaryLink extends React.Component {
  render() {
    return (
      <Link
        to={`/articles/ritual-multivitamins-trans-and-non-binary-individuals`}
        title="A Note on Our Multivitamins for Trans and Non-Binary Individuals"
        target="_blank"
      >
        <Text
          id="build-bundle.non-binary.link"
          defaultMessage="Identify as non-binary?"
        />
      </Link>
    );
  }
}
