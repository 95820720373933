/* eslint-disable */
export const kidsSlides = [
  {
    slideName: "intro",
    componentName: "IntroSlide",
    componentContent: {
      title: "build-bundle.slides.intro.title",
      copy: "build-bundle.slides.intro.copy",
    },
    answers: [
      {
        copy: "build-bundle.slides.intro.button",
        nextSlide: {
          firstRun: "age-gender",
        },
      },
    ],
  },
  {
    slideName: "age-gender",
    componentName: "AgeGenderSlide",
    answers: {
      Male: {
        under50: {
          nextSlide: {
            firstRun: "intermission-3.3",
          },
        },
        over50: {
          nextSlide: {
            firstRun: "intermission-3.4",
          },
        },
      },
      Female: {
        under50: {
          nextSlide: {
            firstRun: "intermission-3.1",
          },
        },
        over50: {
          nextSlide: {
            firstRun: "intermission-3.2",
          },
        },
      },
    },
  },
  {
    slideName: "intermission-3.1",
    componentName: "IntermissionSlide",
    componentContent: {
      smallHeader: "build-bundle.slides.intermission-3.1.smallHeader",
      title: "build-bundle.slides.intermission-3.1.title",
      copy: {
        Female: {
          under50: "build-bundle.slides.intermission-3.1.female-under-50",
          over50: "build-bundle.slides.intermission-3.1.female-over-50",
        },
      },
      imageName: "iron",
      imageIngredient: "build-bundle.slides.intermission-3.1.imageIngredient",
      imageSource: "build-bundle.slides.intermission-3.1.imageSource",
    },
    answers: [
      {
        copy: "build-bundle.slides.intermission-3.1.button",
        nextSlide: {
          firstRun: "prenatal",
        },
      },
      {
        copy: "build-bundle.slides.intermission-3.1.button",
        sku: "EFTG60",
        nextSlide: {
          firstRun: "results",
        },
      },
    ],
  },
  {
    slideName: "intermission-3.2",
    componentName: "IntermissionSlide",
    componentContent: {
      smallHeader: "build-bundle.slides.intermission-3.2.smallHeader",
      title: "build-bundle.slides.intermission-3.2.title",
      copy: "build-bundle.slides.intermission-3.2.copy",
      imageName: "vitaminB12",
      imageIngredient: "build-bundle.slides.intermission-3.2.imageIngredient",
      imageSource: "build-bundle.slides.intermission-3.2.imageSource",
    },
    answers: [
      {
        copy: "build-bundle.slides.intermission-3.2.button",
        nextSlide: {
          firstRun: "postmenopausal",
        },
      },
    ],
  },
  {
    slideName: "intermission-3.3",
    componentName: "IntermissionSlide",
    componentContent: {
      smallHeader: "build-bundle.slides.intermission-3.3.smallHeader",
      title: "build-bundle.slides.intermission-3.3.title",
      copy: {
        Male: {
          under50: "build-bundle.slides.intermission-3.3.male-under-50",
          over50: "build-bundle.slides.intermission-3.3.male-under-50",
        },
      },
      imageName: "vitaminA",
      imageIngredient: "build-bundle.slides.intermission-3.3.imageIngredient",
      imageSource: "build-bundle.slides.intermission-3.3.imageSource",
    },
    answers: [
      {
        copy: "build-bundle.slides.intermission-3.3.button",
        sku: "EFM60",
        nextSlide: {
          firstRun: "results",
        },
      },
      {
        copy: "build-bundle.slides.intermission-3.3.button",
        sku: "EFTB60",
        nextSlide: {
          firstRun: "results",
        },
      },
    ],
  },
  {
    slideName: "intermission-3.4",
    componentName: "IntermissionSlide",
    componentContent: {
      smallHeader: "build-bundle.slides.intermission-3.4.smallHeader",
      title: "build-bundle.slides.intermission-3.4.title",
      copy: "build-bundle.slides.intermission-3.4.copy",
      imageName: "magnesium",
      imageIngredient: "build-bundle.slides.intermission-3.4.imageIngredient",
      imageSource: "build-bundle.slides.intermission-3.4.imageSource",
    },
    answers: [
      {
        copy: "build-bundle.slides.intermission-3.4.button",
        sku: "EFM50PLUS60",
        nextSlide: {
          firstRun: "results",
        },
      },
    ],
  },
  {
    slideName: "prenatal",
    componentName: "PrenatalSlide",
    answers: [
      {
        copy: "build-bundle.slides.prenatal.answer-1",
        nextSlide: {
          firstRun: "intermission-4.1.2",
        },
      },
      {
        copy: "build-bundle.slides.prenatal.answer-2",
        nextSlide: {
          firstRun: "intermission-4.1.1",
        },
      },
      {
        copy: "build-bundle.slides.prenatal.answer-3",
        nextSlide: {
          firstRun: "intermission-4.1.3",
        },
      },
    ],
  },
  {
    slideName: "intermission-4.1.1",
    componentName: "IntermissionSlide",
    componentContent: {
      smallHeader: "build-bundle.slides.intermission-4.1.1.smallHeader",
      title: "build-bundle.slides.intermission-4.1.1.title",
      copy: "build-bundle.slides.intermission-4.1.1.copy",
      imageName: "folate",
      imageIngredient: "build-bundle.slides.intermission-4.1.1.imageIngredient",
      imageSource: "build-bundle.slides.intermission-4.1.1.imageSource",
    },
    answers: [
      {
        copy: "build-bundle.slides.intermission-4.1.1.button",
        sku: "EPRE60",
        nextSlide: {
          firstRun: "results",
        },
      },
    ],
  },
  {
    slideName: "intermission-4.1.2",
    componentName: "IntermissionSlide",
    componentContent: {
      smallHeader: "build-bundle.slides.intermission-4.1.2.smallHeader",
      title: "build-bundle.slides.intermission-4.1.2.title",
      copy: "build-bundle.slides.intermission-4.1.2.copy",
      imageName: "folate",
      imageIngredient: "build-bundle.slides.intermission-4.1.2.imageIngredient",
      imageSource: "build-bundle.slides.intermission-4.1.2.imageSource",
    },
    answers: [
      {
        copy: "build-bundle.slides.intermission-4.1.2.button",
        sku: "EFW60",
        nextSlide: {
          firstRun: "results",
        },
      },
    ],
  },
  {
    slideName: "intermission-4.1.3",
    componentName: "IntermissionSlide",
    componentContent: {
      smallHeader: "build-bundle.slides.intermission-4.1.3.smallHeader",
      title: "build-bundle.slides.intermission-4.1.3.title",
      copy: "build-bundle.slides.intermission-4.1.3.copy",
      imageName: "omega3",
      imageIngredient: "build-bundle.slides.intermission-4.1.3.imageIngredient",
      imageSource: "build-bundle.slides.intermission-4.1.3.imageSource",
    },
    answers: [
      {
        copy: "build-bundle.slides.intermission-4.1.3.button",
        sku: "EPOST60",
        nextSlide: {
          firstRun: "results",
        },
      },
    ],
  },
  {
    slideName: "intermission-7.1.2",
    componentName: "IntermissionSlide",
    componentContent: {
      smallHeader: "build-bundle.slides.intermission-7.1.2.smallHeader",
      title: "build-bundle.slides.intermission-7.1.2.title",
      copy: "build-bundle.slides.intermission-7.1.2.copy",
      imageName: "omega3",
      imageIngredient: "build-bundle.slides.intermission-7.1.2.imageIngredient",
      imageSource: "build-bundle.slides.intermission-7.1.2.imageSource",
    },
    answers: [
      {
        copy: "build-bundle.slides.intermission-7.1.2.button",
        sku: "EFW60",
        nextSlide: {
          firstRun: "results",
        },
      },
    ],
  },
  {
    slideName: "postmenopausal",
    componentName: "PostmenopausalSlide",
    answers: [
      {
        copy: "build-bundle.slides.postmenopausal.answer-1",
        nextSlide: {
          firstRun: "intermission-4.2.2",
        },
      },
      {
        copy: "build-bundle.slides.postmenopausal.answer-2",
        nextSlide: {
          firstRun: "intermission-4.2.1",
        },
      },
    ],
  },
  {
    slideName: "intermission-4.2.1",
    componentName: "IntermissionSlide",
    componentContent: {
      smallHeader: "build-bundle.slides.intermission-4.2.1.smallHeader",
      title: "build-bundle.slides.intermission-4.2.1.title",
      copy: "build-bundle.slides.intermission-4.2.1.copy",
      imageName: "iron",
      imageIngredient: "build-bundle.slides.intermission-4.2.1.imageIngredient",
      imageSource: "build-bundle.slides.intermission-4.2.1.imageSource",
    },
    answers: [
      {
        copy: "build-bundle.slides.intermission-4.2.1.button",
        sku: "E50PLUS60",
        nextSlide: {
          firstRun: "results",
        },
      },
    ],
  },
  {
    slideName: "intermission-4.2.2",
    componentName: "IntermissionSlide",
    componentContent: {
      smallHeader: "build-bundle.slides.intermission-4.2.2.smallHeader",
      title: "build-bundle.slides.intermission-4.2.2.title",
      copy: "build-bundle.slides.intermission-4.2.2.copy",
      imageName: "vitaminB12",
      imageIngredient: "build-bundle.slides.intermission-4.2.2.imageIngredient",
      imageSource: "build-bundle.slides.intermission-4.2.2.imageSource",
    },
    answers: [
      {
        copy: "build-bundle.slides.intermission-4.2.2.button",
        sku: "EFW60",
        nextSlide: {
          firstRun: "results",
        },
      },
    ],
  },
  {
    slideName: "results",
    componentName: "Results",
    answers: [
      {
        nextSlide: {
          firstRun: "household",
        },
      },
    ],
  },
  {
    slideName: "household",
    componentName: "HouseholdBuilderSlide",
    answers: [
      {
        nextSlide: {
          firstRun: "intermission-ProductDifference",
        },
      },
    ],
  },
  {
    slideName: "intermission-ProductDifference",
    componentName: "ValuePropsIntermission",
    componentContent: {
      smallHeader:
        "build-bundle.slides.intermission-ProductDifference.smallHeader",
      title: "build-bundle.slides.intermission-ProductDifference.title",
      valueProps: [
        {
          svg: "Gene",
          heading:
            "build-bundle.slides.intermission-ProductDifference.props.gene.heading",
          subheading:
            "build-bundle.slides.intermission-ProductDifference.props.gene.subheading",
        },
        {
          svg: "Traceable",
          heading:
            "build-bundle.slides.intermission-ProductDifference.props.traceable.heading",
          subheading:
            "build-bundle.slides.intermission-ProductDifference.props.traceable.subheading",
        },
        {
          svg: "Vegan",
          heading:
            "build-bundle.slides.intermission-ProductDifference.props.vegan.heading",
          subheading:
            "build-bundle.slides.intermission-ProductDifference.props.vegan.subheading",
        },
        {
          svg: "Nonausea",
          heading:
            "build-bundle.slides.intermission-ProductDifference.props.nonausea.heading",
          subheading:
            "build-bundle.slides.intermission-ProductDifference.props.nonausea.subheading",
        },
      ],
    },
    answers: [
      {
        copy: "build-bundle.slides.intermission-ProductDifference.button",
        nextSlide: {
          firstRun: "final-results",
        },
      },
    ],
  },
  {
    slideName: "final-results",
    componentName: "Results",
  },
];
