import React from "react";
import styled from "styled-components";

// Utils
import { Color, rem, responsive } from "../../../utils/style";
import { getBundleCartLimit } from "../../../utils/bundle";
import metrics from "../../../utils/metrics";

// Components
import YellowArrow from "../YellowArrow";
import HouseholdMember from "../HouseholdMember";
import QuizAlternateButton from "../QuizAlternateButton";
import NonBinaryLink from "../NonBinaryLink";
import Text from "../../Text";

// Styled Elements
const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 56px 24px;

  ${responsive.sm`
    padding: 80px 24px;
  `};
`;

const Title = styled.h1`
  color: ${Color.ritualBlue};
  font-weight: 500;
  font-size: ${rem(26)};
  line-height: ${rem(36)};
  letter-spacing: -0.3px;
  text-align: center !important;
  margin: 0 0 24px;
  max-width: 256px;

  ${responsive.sm`
    font-size: ${rem(48)};
    line-height: ${rem(54)};
    letter-spacing: -1.4px;
    margin-bottom: 32px;
    max-width: 430px;
  `};
`;

const Form = styled.form`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${responsive.sm`
    max-width: 370px;
  `};
`;

const ArrowWrapper = styled.div.attrs({
  className:
    "d-flex align-items-center justify-content-center flex-direction-column",
})`
  position: relative;
`;

const Arrow = styled(YellowArrow)`
  position: absolute;
  bottom: 1px;
  left: 30px;
  transform: rotate(28deg);

  ${responsive.sm`
    left: 32px;
    bottom: 1px;
    transform: rotate(61deg);
  `};
`;

const InputWrapper = styled.div`
  width: 100%;
`;

const AddAnotherPerson = styled.button`
  display: block;
  appearance: none;
  border: 0;
  padding: 0;
  margin: 0 auto 40px;
  border-bottom: 2px solid ${Color.ritualBlue};
  background: none;

  text-align: center;
  color: ${Color.ritualBlue};
  font-size: ${rem(14)};
  line-height: ${rem(24)};
  font-weight: 500;

  ${responsive.sm`
    font-size: ${rem(16)};
    line-height: ${rem(26)};
    margin-bottom: 48px;
  `};

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }
`;

export default class HouseholdBuilderSlide extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disabledStates: [],
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { addHouseholdMember, userInput } = this.props;

    // Add first household member if they do not exist
    if (userInput.length < 2) {
      addHouseholdMember();
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    const { slide, userInput } = this.props;

    let answer = { ...slide.answers[0] };

    // Exclude first user
    const householdUsers = userInput.slice(1);
    const newProducts = householdUsers.map(
      user => user.product && user.product,
    );

    const ages = householdUsers.map(user => user.age);
    const genders = householdUsers.map(user => user.gender);

    metrics.track("Quiz Personal Info Entered", {
      location: slide.slideName,
      gender: genders.toString(),
      age: ages.toString(),
    });

    metrics.track("Quiz CTA Clicked", {
      title: "Continue",
      location: slide.slideName,
      numberInHousehold: newProducts.length,
    });

    answer.sku = newProducts;
    this.props.nextSlide(answer);
  }

  updateDisabledState(index, value) {
    const disabledStates = [...this.state.disabledStates];
    disabledStates[index] = value;

    this.setState({
      disabledStates,
    });
  }

  addAnotherPerson(e) {
    e.preventDefault();

    // Add disabled state for newly added household member
    let disabledStates = [...this.state.disabledStates];
    disabledStates.push(true);

    this.setState({
      disabledStates,
    });

    this.props.addHouseholdMember();
  }

  removeUser(index) {
    // Add disabled state for newly added household member
    let disabledStates = [...this.state.disabledStates];
    disabledStates.splice(1, index - 1);

    this.setState({
      disabledStates,
    });

    this.props.removeHouseholdMember(index);
  }

  render() {
    const { disabledStates } = this.state;
    const { userInput } = this.props;

    const householdUsers = userInput.slice(1);

    const cartLimit = getBundleCartLimit();
    const canAddAnother = userInput.length < cartLimit;

    const disabled =
      !disabledStates.length || disabledStates.some(state => !!state);

    return (
      <Wrapper>
        <ArrowWrapper>
          <Title>
            <Text
              id="build-bundle.slides.household.title"
              defaultMessage="Tell us about your household"
            />
          </Title>
          <Arrow />
        </ArrowWrapper>
        <Form onSubmit={this.handleSubmit}>
          <InputWrapper>
            {householdUsers.map((user, i) => {
              const userIndex = i + 1;
              return (
                <HouseholdMember
                  key={i}
                  canRemove={i !== 0}
                  userIndex={userIndex}
                  user={user}
                  updateDisabledState={this.updateDisabledState.bind(this)}
                  removeUser={this.removeUser.bind(this)}
                  {...this.props}
                />
              );
            })}
          </InputWrapper>
          {canAddAnother && (
            <AddAnotherPerson onClick={this.addAnotherPerson.bind(this)}>
              +{" "}
              <Text
                id="build-bundle.slides.household.add"
                defaultMessage="Add another person"
              />
            </AddAnotherPerson>
          )}
          <QuizAlternateButton type="submit" disabled={disabled}>
            <Text
              id="build-bundle.slides.household.button"
              defaultMessage="Continue"
            />
          </QuizAlternateButton>
        </Form>

        <NonBinaryLink />
      </Wrapper>
    );
  }
}
