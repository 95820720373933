import React from "react";
import styled from "styled-components";


// Services
import intl from "../../../services/intl";
import { navigate } from "../../../services/navigation";

// Utils
import { Font, rem, responsive, Color, rgba } from "../../../utils/style";
import { Icons } from "../../../utils/svg";
import {
  getDiscountData,
  getPlanIdAndQuantityArray,
  getBundleCartLimit,
} from "../../../utils/bundle";
import {
  trackCustomBundleAdded,
  trackCustomBundleViewed,
} from "../../../utils/tracking/bundle";
import metrics from "../../../utils/metrics";
import {
  getPlanForProductSku,
  getContentfulProductForPlan,
} from "../../../utils/planToProduct";

// Components
import ProductResult from "../ProductResult";
import QuizRibbon from "../QuizRibbon";
import MagicLink from "../../MagicLink";
import Text from "../../Text";

// Store
import { connect } from "react-redux";
import { addProductsToCart } from "../../../store/cart/actions";

// Styled Elements
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 48px 16px 56px;

  ${responsive.sm`
    max-width: 580px;
    margin: 80px auto;
  `};

  ${responsive.md`
    max-width: 770px;
  `}
`;

const Copy = styled.div`
  h1 {
    font-size: ${rem(18)};
    letter-spacing: 0px;
    line-height: ${rem(26)};
    text-align: center;
    margin-bottom: 0;
    width: 256px;
  }

  ${responsive.sm`
    h1 {
      font-size: ${rem(30)};
      letter-spacing: -0.4px;
      line-height: ${rem(36)};
      width: 500px;
    }
  `}
`;

const ProductResultsSection = styled.div`
  background-color: ${Color.white};
  margin: 24px 16px;
  padding: 16px;
  width: 100%;

  & > div {
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  ${responsive.sm`
    margin: 40px 70px;
    padding: 24px;

    & > div {
      padding-top: 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.16);
    }
  `}

  ${responsive.md`
    width: 770px;
    margin: 40px auto;
    padding: 24px 32px;
  `}
`;

const ResultsButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 12px 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.16);
  z-index: 399;
  background: ${Color.white};

  ${responsive.sm`
    position: relative;
    background: transparent;
    border-top: none;
    padding: 0;
    z-index: auto;
  `}
`;

const ResultsButton = styled.button`
  background-color: ${Color.ritualBlue};
  border: none;
  border-radius: 25px;
  padding: 8px 32px;
  width: 100%;

  &:disabled {
    opacity: 0.56;
  }

  &:hover {
    background: ${Color.ritualYellow};
    p {
      color: ${Color.ritualBlue};

      span {
        color: ${Color.ritualDisabledBlue};
      }
    }
  }

  &:active {
    outline: none;
  }

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }

  p {
    color: ${Color.white};
    font-size: ${rem(14)};
    line-height: ${rem(24)};
    text-align: center;
    margin-bottom: 0;

    span {
      color: ${rgba(Color.white, 0.56)};
      text-decoration: line-through;
    }
  }

  ${responsive.sm`
    margin-bottom: 32px;
    padding: 12px 32px;
    width: auto;

    p {
      font-size: ${rem(16)};
      line-height: ${rem(26)};
    }
  `}
`;

const FinishAddToCart = styled.div`
  margin-bottom: 24px;
  width: 250px;

  p {
    ${Font.dutch};
    color: ${rgba(Color.ritualBlue, 0.72)};
    font-size: ${rem(14)};
    font-weight: normal;
    line-height: ${rem(24)};
    text-align: center;
    margin-bottom: 0;
  }

  a {
    color: ${rgba(Color.ritualBlue, 0.72)};
    border-bottom: 1px solid ${rgba(Color.ritualBlue, 0.72)};
    margin-left: 4px;
  }

  ${responsive.sm`
    margin-bottom: 64px;
    width: 100%;
  `}
`;

const ValuePropsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 16px 16px 0;

  ${responsive.sm`
    flex-direction: row;
    justify-content: center;
    width: auto;
    margin: 0 -80px;
  `}
`;

const ValueProp = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }

  div {
    flex-shrink: 0;
    height: 32px;
    width: 32px;
    position: relative;

    svg {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      height: 100%;
      width: 100%;
    }
  }

  p {
    font-size: ${rem(14)};
    font-weight: 300;
    line-height: ${rem(20)};
    text-align: left;
    margin: auto 8px;
  }

  ${responsive.sm`
    flex-direction: column;
    width: 134px;
    margin-right: 24px;
    margin-bottom: 0;

    &:last-of-type {
      margin-right: 0;
    }

    div {
      height: 40px;
      width: 40px;
      margin-bottom: 16px;
    }

    p {
      font-size: ${rem(16)};
      font-weight: 300;
      line-height: ${rem(22)};
      text-align: center;
      margin: 0;
    }
  `}

  ${responsive.md`
    margin-right: 40px;
  `}
`;

export class Results extends React.Component {
  constructor(props) {
    super(props);

    const { userInput } = props;

    const planIds = [];

    if (this._isFinalResults()) {
      // If this is final results slide, display all products in the userInput.
      userInput.forEach(input => {
        // If the input does not have a product, it is ignored.
        if (!input.product) return;
        const plan = getPlanForProductSku(input.product);
        planIds.push(plan.id);
      });
    } else {
      // Default to displaying the planId for the first product in the userInput.
      planIds.push(getPlanForProductSku(userInput[0].product).id);
    }

    const planIdAndQuantityArray = getPlanIdAndQuantityArray(planIds);

    this.state = {
      isProcessing: false,
      planIdAndQuantityArray,
    };
  }

  componentDidMount() {
    if (!this.state.planIdAndQuantityArray.length) {
      navigate("/shop-bundles");
    }

    const bundle = this._getBundle();

    // Update the top-level results bundle whenever the results slide is
    // rendered. The top level bundle is used to determine which products
    // are added during bundle builder abandonment.
    this.props.updateResultsBundle(bundle);
    trackCustomBundleViewed(bundle);
  }

  _isFinalResults() {
    return this.props.slide.slideName === "final-results";
  }

  _getTotalQuantity() {
    let sum = 0;
    this.state.planIdAndQuantityArray.forEach(obj => {
      sum += obj.quantity;
    });
    return sum;
  }

  // Gets the bundle data for tracking. This does not always match the
  // userInput, as the product quantities may have been adjusted.
  _getBundle() {
    const { planIdAndQuantityArray } = this.state;
    const bundle = [];

    planIdAndQuantityArray.forEach(obj => {
      const { quantity, planId } = obj;
      const product = getContentfulProductForPlan(planId);

      // Push a new object to the results bundle for each quanity in the array.
      // Items with quantity 0 are omitted.
      for (let i = 0; i < quantity; i++) {
        bundle.push(getPlanForProductSku(product.sku));
      }
    });

    return bundle;
  }

  onQuantityChange(planId, quantity) {
    const { planIdAndQuantityArray } = this.state;

    planIdAndQuantityArray.find(obj => {
      const match = obj.planId === planId;
      if (match) {
        obj.quantity = quantity;
      }
      return match;
    });

    metrics.track("Quantity Changed", {
      quantity,
    });

    this.setState(
      {
        planIdAndQuantityArray,
      },
      () => {
        // Update the top-level results bundle with the new quantity.
        this.props.updateResultsBundle(this._getBundle());
      },
    );
  }

  handleResultsButtonClick() {
    if (this._isFinalResults()) {
      this.handleAddToCart();
    } else {
      this.hanldeAddAnother();
    }
  }

  handleAddToCart() {
    trackCustomBundleAdded(this._getBundle());
    this.setState({
      isProcessing: true,
    });

    // Reove all plans with no quantity when adding the products to the cart.
    const filteredProductsToAdd = this.state.planIdAndQuantityArray.filter(
      obj => obj.quantity > 0,
    );

    this.props.dispatchAddProductsToCart(filteredProductsToAdd);
    navigate("/cart");
  }

  hanldeAddAnother() {
    this.props.nextSlide(this.props.slide.answers[0]);

    metrics.track("CTA Clicked", {
      title: "Add Another Person",
      location: "Quiz result page",
      nonInteraction: false,
    });
  }

  renderFinishAddToCart() {
    return (
      <FinishAddToCart>
        <p>
          <Text
            id="build-bundle.slides.results.finish-add-copy"
            defaultMessage="Not interested in adding another product?"
          />
          <MagicLink onClick={this.handleAddToCart.bind(this)} to="/cart">
            <Text
              id="build-bundle.slides.results.finish-add-cta"
              defaultMessage="Finish & Add to Cart"
            />
          </MagicLink>
        </p>
      </FinishAddToCart>
    );
  }

  renderValueProp(valueProp) {
    let Icon = Icons[valueProp.icon];
    return (
      <ValueProp key={valueProp.icon}>
        <div>
          <Icon />
        </div>
        <p>{valueProp.copy}</p>
      </ValueProp>
    );
  }

  render() {
    const { discount } = this.props;
    const { isProcessing, planIdAndQuantityArray } = this.state;

    const isFinalResults = this._isFinalResults();
    const multiItem = this._getTotalQuantity() > 1;

    const bundle = this._getBundle();

    const total =
      bundle.reduce((total, product) => {
        const amount = product?.amount || 0;
        return total + amount;
      }, 0) / 100;

    const discountData = getDiscountData(total);

    const banner = multiItem
      ? discountData.amountOff
        ? intl.t(
            "build-bundle.slides.results.multi-bundle.unlock",
            "You unlocked a {off} bundle discount",
            { off: intl.formatCurrency(discountData.amountOff, { round: true }) },
          )
        : intl.t(
            "build-bundle.slides.results.multi-bundle.unlock-percent",
            "You unlocked a {off}% bundle discount",
            { off: discountData.percentOff },
          )
      : intl.t(
          "build-bundle.slides.results.single-bundle.banner",
          "Add another to save {copy}",
          { copy: discountData.sentenceCopy },
        );

    const heading = isFinalResults
      ? intl.t(
          "build-bundle.slides.results.multi-bundle.heading",
          "Getting key nutrients you need should be easy, so we help make it that way.",
        )
      : intl.t(
          "build-bundle.slides.results.single-bundle.heading-new",
          "Nice. Here's what we recommend for you. Now, let’s find a Ritual for the rest of your household.",
        );

    const ctaHtml = isFinalResults
      ? multiItem
        ? `<p>${intl.t(
            "build-bundle.slides.results.multi-item.cta",
            "Add to Cart — {copy}",
            { copy: intl.formatCurrency(discountData.discountPrice, { round: true }) },
          )} <span>${intl.formatCurrency(total, { round: true })}</span></p>`
        : `<p>${intl.t(
            "build-bundle.slides.results.single-item.cta",
            "Add to Cart — {copy}",
            { copy: intl.formatCurrency(total, { round: true }) },
          )}</p>`
      : `<p>${intl.t(
          "build-bundle.slides.results.single-bundle.cta",
          "Keep Going — {copy}",
          { copy: discountData.titleCopy },
        )}</p>`;

    const valueProps = intl.unformattedTranslation(
      "build-bundle.slides.results.valueProps",
    );

    return (
      <Wrapper>
        {discount && <QuizRibbon>{banner}</QuizRibbon>}
        <Copy>
          <h1>{heading}</h1>
        </Copy>

        <ProductResultsSection>
          {planIdAndQuantityArray &&
            planIdAndQuantityArray.map((obj, i) => {
              const { planId, quantity } = obj;
              const product = getContentfulProductForPlan(planId);
              return (
                <ProductResult
                  key={i}
                  showSupplementFacts={false}
                  showQuantityPicker={isFinalResults}
                  planId={planId}
                  product={product}
                  productQuantity={quantity}
                  totalQuantity={this._getTotalQuantity()}
                  maxQuantity={getBundleCartLimit()}
                  onQuantityChange={this.onQuantityChange.bind(this)}
                />
              );
            })}
        </ProductResultsSection>

        <ResultsButtonContainer>
          <ResultsButton
            disabled={isProcessing}
            onClick={this.handleResultsButtonClick.bind(this)}
            dangerouslySetInnerHTML={{ __html: ctaHtml }}
          />
        </ResultsButtonContainer>

        {!isFinalResults && this.renderFinishAddToCart()}

        <ValuePropsContainer>
          {valueProps.map(valueProp => {
            return this.renderValueProp(valueProp);
          })}
        </ValuePropsContainer>
      </Wrapper>
    );
  }
}

export default connect(null, {
  dispatchAddProductsToCart: addProductsToCart,
})(Results);
