import React from "react";
import styled from "styled-components";

// Utils
import { Color, responsive } from "../../utils/style";

// Components
import Img from "gatsby-image/withIEPolyfill";

// Styled Elements
const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 0 8px;

  ${responsive.sm`
    padding: 0 24px;
  `};

  ${responsive.lg`
    padding-left: 40px;
    padding-right: 40px;
  `};
`;

const SlideContainerElement = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  min-height: calc(100vh - 66px);

  ${responsive.sm`
    min-height: calc(100vh - 68px - 24px);
  `};

  ${responsive.md`
    min-height: calc(100vh - 84px - 24px);
  `};

  ${responsive.lg`
    min-height: calc(100vh - 76px - 40px);
  `};
`;

const SlideContent = styled.div`
  position: relative;
  z-index: 10;
  width: 100%;
  opacity: 1;
  transition: opacity 0.6s;
  height: 100%;
  min-height: calc(100vh - 66px);

  ${responsive.sm`
    min-height: calc(100vh - 68px - 24px);
  `};

  ${responsive.md`
    min-height: calc(100vh - 84px - 24px);
  `};

  ${responsive.lg`
    min-height: calc(100vh - 84px - 40px);
  `};

  &.fade {
    opacity: 0;
  }
`;

const SlideBackgroundImage = styled(Img)`
  z-index: 1;
`;

const ProgressWrapper = styled.div`
  width: 100%;
  background-color: ${Color.ritualGrey};
`;

const ProgressBar = styled.div`
  width: 100%;
  background-color: ${Color.ritualBlue};
  transition: width 0.2s;
  height: 6px;
  width: ${p => 100 - p.incompletionPercent}%;

  ${responsive.sm`
    height: 8px;
  `};
`;

export default class SlideContainer extends React.Component {
  render() {
    const {
      backgroundImage,
      completionPercent,
      children,
      transition,
    } = this.props;

    return (
      <Wrapper>
        <ProgressWrapper>
          <ProgressBar incompletionPercent={100 - completionPercent} />
        </ProgressWrapper>
        <SlideContainerElement>
          <SlideContent className={transition ? "fade" : ""}>
            {children}
          </SlideContent>
          {backgroundImage && (
            <SlideBackgroundImage
              fluid={backgroundImage.fluid}
              alt={backgroundImage.title}
              backgroundColor={Color.ritualGrey}
              objectFit="cover"
              style={{
                position: "absolute",
                top: "0",
                right: "0",
                width: "100%",
                height: "100%",
                userSelect: "none",
                userDrag: "none",
                pointerEvents: "none",
                touchCallout: "none",
              }}
            />
          )}
        </SlideContainerElement>
      </Wrapper>
    );
  }
}
