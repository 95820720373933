// Utils
import { getPropertiesForProduct } from "./helpers";
import metrics from "../metrics";

export function trackCustomBundleUpdated(planId) {
  const productProperties = getPropertiesForProduct(planId);

  metrics.track("Custom Bundle Updated", productProperties);
}

export function trackCustomBundleAdded(bundle) {
  const products = _getBundleProductProperties(bundle);

  metrics.track("Custom Bundle Added", {
    products: products,
    quantity: bundle.length,
  });
}

export function trackCustomBundleViewed(bundle) {
  const products = _getBundleProductProperties(bundle);

  metrics.track("Custom Bundle Viewed", {
    products: products,
    quantity: bundle.length,
  });
}

function _getBundleProductProperties(bundle) {
  return bundle.map(plan => {
    return getPropertiesForProduct(plan.id);
  });
}
