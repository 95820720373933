import React from "react";
import styled from "styled-components";
import { graphql, StaticQuery } from "gatsby";

// Services
import intl from "../../../services/intl";

// Utils
import { Color, Font, rem, responsive } from "../../../utils/style";

// Components
import Img from "gatsby-image/withIEPolyfill";
import QuizButton from "../QuizButton";
import Text from "../../Text";

// Styled Elements
const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 66px);

  ${responsive.sm`
    flex-direction: row;
    min-height: calc(100vh - 68px - 24px);
  `};

  ${responsive.md`
    min-height: calc(100vh - 84px - 24px);
  `};

  ${responsive.lg`
    min-height: calc(100vh - 84px - 40px);
  `};
`;

const SlideContentWrapper = styled.div`
  order: 1;
  display: flex;
  justify-content: flex-start;

  ${responsive.sm`
    order: 2;
    flex: 0 0 50%;
  `}

  ${responsive.md`
    justify-content: center;
  `}
`;

const SlideContent = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 56px 24px 0;
  order: 1;
  height: calc(100% - 238px);

  ${responsive.sm`
    height: 100%;
    min-height: calc(100vh - 60px - 24px);
    justify-content: center;
    align-items: flex-start;
    padding: 80px 40px;
  `};

  ${responsive.md`
    max-width: 454px;
    min-height: calc(100vh - 76px - 24px);
  `};

  ${responsive.lg`
    min-height: calc(100vh - 76px - 40px);
  `};
`;

const ImageDescription = styled.div`
  position: relative;
  z-index: 10;
  order: 2;
  padding: 16px;
  height: 238px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;

  ${responsive.sm`
    order: 1;
    height: 100%;
    flex: 0 0 50%;
    padding: 24px;
    justify-content: flex-start;
    align-items: flex-start;
  `};

  ${responsive.md`
    padding-top: 32px;
    padding-left: 32px;
  `};
`;

const SmallHeader = styled.p`
  color: rgba(20, 43, 111, 0.56);
  font-weight: 500;
  font-size: ${rem(12)};
  line-height: ${rem(20)};
  letter-spacing: 0.8px;
  text-transform: uppercase;
  text-align: center !important;
  margin: 0 0 8px;

  ${responsive.sm`
    font-size: ${rem(14)};
    line-height: ${rem(22)};
    letter-spacing: 0.88px;
    text-align: left !important;
    margin-bottom: 16px;
  `};

  ${responsive.md`
    font-size: ${rem(16)};
    line-height: ${rem(26)};
    letter-spacing: 1px;
  `};
`;

const Title = styled.h1`
  color: ${Color.ritualBlue};
  font-weight: 500;
  font-size: ${rem(20)};
  line-height: ${rem(30)};
  text-align: center !important;
  margin: 0 0 16px;

  ${responsive.sm`
    font-size: ${rem(30)};
    line-height: ${rem(36)};
    letter-spacing: -0.4px;
    text-align: left !important;
    margin-bottom: 24px;
  `};

  ${responsive.md`
    font-size: ${rem(34)};
    line-height: ${rem(40)};
    letter-spacing: -0.5px;
  `};
`;

const Copy = styled.p`
  ${Font.dutch};
  color: ${Color.ritualBlue};
  font-weight: normal;
  font-size: ${rem(14)};
  line-height: ${rem(24)};
  text-align: center !important;
  margin: 0 0 32px;

  ${responsive.sm`
    text-align: left !important;
    margin-bottom: 40px;
  `};

  ${responsive.md`
    font-size: ${rem(16)};
    line-height: ${rem(26)};
    margin-bottom: 40px;
  `};
`;

const Ingredient = styled.p`
  color: rgba(20, 43, 111, 0.56);
  font-weight: 500;
  font-size: ${rem(14)};
  line-height: ${rem(24)};
  text-align: right !important;
  margin: 0;

  ${responsive.sm`
    text-align: left !important;
  `};

  ${responsive.md`
    font-size: ${rem(16)};
    line-height: ${rem(26)};
  `};
`;

const Source = styled.p`
  color: rgba(20, 43, 111, 0.56);
  font-weight: 500;
  font-size: ${rem(12)};
  line-height: ${rem(18)};
  text-align: right !important;
  margin: 0;

  ${responsive.sm`
    text-align: left !important;
  `};

  ${responsive.md`
    font-size: ${rem(14)};
    line-height: ${rem(24)};
  `};
`;

const Button = styled(QuizButton)`
  width: 100% !important;

  ${responsive.sm`
    width: auto !important;
  `};
`;

export const IntermissionComponent = class Intermission extends React.Component {
  constructor(props) {
    super(props);

    const { contentfulData } = this.props;

    this.images = {
      folate: {
        desktop: intl.cf(contentfulData?.folateDesktopImages?.nodes, true),
        mobile: intl.cf(contentfulData?.folateMobileImages?.nodes, true),
      },
      iron: {
        desktop: intl.cf(contentfulData?.ironDesktopImages?.nodes, true),
        mobile: intl.cf(contentfulData?.ironMobileImages?.nodes, true),
      },
      magnesium: {
        desktop: intl.cf(contentfulData?.magnesiumDesktopImages?.nodes, true),
        mobile: intl.cf(contentfulData?.magnesiumMobileImages?.nodes, true),
      },
      omega3: {
        desktop: intl.cf(contentfulData?.omega3DesktopImages?.nodes, true),
        mobile: intl.cf(contentfulData?.omega3MobileImages?.nodes, true),
      },
      vitaminA: {
        desktop: intl.cf(contentfulData?.vitaminADesktopImages?.nodes, true),
        mobile: intl.cf(contentfulData?.vitaminAMobileImages?.nodes, true),
      },
      vitaminB12: {
        desktop: intl.cf(contentfulData?.vitaminB12DesktopImages?.nodes, true),
        mobile: intl.cf(contentfulData?.vitaminB12MobileImages?.nodes, true),
      },
      vitaminD3: {
        desktop: intl.cf(contentfulData?.vitaminD3DesktopImages?.nodes, true),
        mobile: intl.cf(contentfulData?.vitaminD3MobileImages?.nodes, true),
      },
      pills: {
        desktop: intl.cf(contentfulData?.pillsDesktopImages?.nodes, true),
        mobile: intl.cf(contentfulData?.pillsMobileImages?.nodes, true),
      },
      kidsGummy: {
        desktop: intl.cf(contentfulData?.kidsGummyDesktopImages?.nodes, true),
        mobile: intl.cf(contentfulData?.kidsGummyMobileImages?.nodes, true),
      },
    };
  }

  handleClick() {
    const { nextSlide } = this.props;
    const answer = this.getAnswer();

    nextSlide(answer);
  }

  renderImage(imageName) {
    const selectedImage = this.images[imageName];

    if (!selectedImage) return null;

    const style = {
      position: "absolute",
      zIndex: "0",
      userSelect: "none",
      userDrag: "none",
      pointerEvents: "none",
      touchCallout: "none",
    };

    const mobileStyle = {
      ...style,
      right: 0,
      top: "calc(100% - (100% - 295px))",
      left: 0,
      width: "100%",
    };

    const desktopStyle = {
      ...style,
      top: "50%",
      transform: "translateY(-50%)",
      left: 0,
      width: "50%",
    };

    return (
      <>
        <Img
          className="d-block d-sm-none"
          fluid={selectedImage.mobile.fluid}
          loading="eager"
          alt={selectedImage.mobile.description}
          style={mobileStyle}
        />
        <Img
          className="d-none d-sm-block"
          fluid={selectedImage.desktop.fluid}
          loading="eager"
          alt={selectedImage.desktop.description}
          style={desktopStyle}
        />
      </>
    );
  }

  getAnswer() {
    const {
      age,
      slide: { answers },
    } = this.props;

    // If ages 13-17 and slide has alternate answer route
    if (answers[1] && age > 12 && age < 18) {
      return answers[1];
    }

    return answers[0];
  }

  render() {
    const { age, gender, slide } = this.props;
    const {
      smallHeader,
      title,
      copy,
      imageName,
      imageIngredient,
      imageSource,
    } = slide.componentContent;

    const answer = this.getAnswer();

    let slideCopy = copy;

    if (typeof copy === "object") {
      const ageCopy = age < 50 ? "under50" : "over50";
      slideCopy = copy?.[gender]?.[ageCopy];
    }

    return (
      <Wrapper>
        {imageName && this.renderImage(imageName)}
        <SlideContentWrapper>
          <SlideContent>
            <SmallHeader>
              <Text id={smallHeader} />
            </SmallHeader>
            <Title>
              <Text id={title} />
            </Title>
            <Copy>
              <Text id={slideCopy} />
            </Copy>
            <Button clickHandler={this.handleClick.bind(this)}>
              <Text id={answer.copy} />
            </Button>
          </SlideContent>
        </SlideContentWrapper>
        <ImageDescription>
          {imageIngredient && (
            <Ingredient>
              <Text id={imageIngredient} />
            </Ingredient>
          )}
          {imageSource && (
            <Source>
              <Text id={imageSource} />
            </Source>
          )}
        </ImageDescription>
      </Wrapper>
    );
  }
};

export default props => (
  <StaticQuery
    query={componentQuery}
    render={data => <IntermissionComponent {...props} contentfulData={data} />}
  />
);

// GraphQL
const componentQuery = graphql`
  query IntermissionComponentQuery {
    pillsMobileImages: allContentfulAsset(
      filter: { contentful_id: { eq: "4TCtwOgst4aXILZW1RFsZo" } }
    ) {
      nodes {
        node_locale
        description
        fluid(maxWidth: 304, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    pillsDesktopImages: allContentfulAsset(
      filter: { contentful_id: { eq: "1rP3JOaDzkZgXSnAoyMgc9" } }
    ) {
      nodes {
        node_locale
        description
        fluid(maxWidth: 643, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    vitaminD3MobileImages: allContentfulAsset(
      filter: { contentful_id: { eq: "7mB4FYs3Jb1oO4InEuSnvU" } }
    ) {
      nodes {
        node_locale
        description
        fluid(maxWidth: 304, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    vitaminD3DesktopImages: allContentfulAsset(
      filter: { contentful_id: { eq: "7JFoF7iY19f9f6YypGZb7V" } }
    ) {
      nodes {
        node_locale
        description
        fluid(maxWidth: 643, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    vitaminB12MobileImages: allContentfulAsset(
      filter: { contentful_id: { eq: "66M8NXoCP9EqrEW6mm19Pp" } }
    ) {
      nodes {
        node_locale
        description
        fluid(maxWidth: 304, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    vitaminB12DesktopImages: allContentfulAsset(
      filter: { contentful_id: { eq: "1M2s6KeqF0PgOu2eWtITkX" } }
    ) {
      nodes {
        node_locale
        description
        fluid(maxWidth: 643, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    vitaminAMobileImages: allContentfulAsset(
      filter: { contentful_id: { eq: "3KELdN26PCAqMnKdq6x4Qr" } }
    ) {
      nodes {
        node_locale
        description
        fluid(maxWidth: 304, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    vitaminADesktopImages: allContentfulAsset(
      filter: { contentful_id: { eq: "7Giv7T8oep7Fw2H2MaW90Z" } }
    ) {
      nodes {
        node_locale
        description
        fluid(maxWidth: 643, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    folateMobileImages: allContentfulAsset(
      filter: { contentful_id: { eq: "3t1bY5IQZ0QhNIhYeA4Wa2" } }
    ) {
      nodes {
        node_locale
        description
        fluid(maxWidth: 304, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    folateDesktopImages: allContentfulAsset(
      filter: { contentful_id: { eq: "4p1QpCjwxDnZ3kULbtTf9y" } }
    ) {
      nodes {
        node_locale
        description
        fluid(maxWidth: 643, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    ironMobileImages: allContentfulAsset(
      filter: { contentful_id: { eq: "5htfNpDwm8A4uYVmX7hJq1" } }
    ) {
      nodes {
        node_locale
        description
        fluid(maxWidth: 304, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    ironDesktopImages: allContentfulAsset(
      filter: { contentful_id: { eq: "6zjWpLrUcf9GPuVlhZqAIi" } }
    ) {
      nodes {
        node_locale
        description
        fluid(maxWidth: 643, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    magnesiumMobileImages: allContentfulAsset(
      filter: { contentful_id: { eq: "3swwhMzBPopqphBsFeCTQS" } }
    ) {
      nodes {
        node_locale
        description
        fluid(maxWidth: 304, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    magnesiumDesktopImages: allContentfulAsset(
      filter: { contentful_id: { eq: "3D4toMaVVdIL2s3qJGerrH" } }
    ) {
      nodes {
        node_locale
        description
        fluid(maxWidth: 643, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    omega3MobileImages: allContentfulAsset(
      filter: { contentful_id: { eq: "5QKPS0azxeylcSHfDP6Fl8" } }
    ) {
      nodes {
        node_locale
        description
        fluid(maxWidth: 304, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    omega3DesktopImages: allContentfulAsset(
      filter: { contentful_id: { eq: "HHJSaAFk8CJRAlsoS9PLe" } }
    ) {
      nodes {
        node_locale
        description
        fluid(maxWidth: 643, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    kidsGummyMobileImages: allContentfulAsset(
      filter: { contentful_id: { eq: "5uZD1A0FWLcQEIn8TxUdOP" } }
    ) {
      nodes {
        node_locale
        description
        fluid(maxWidth: 304, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    kidsGummyDesktopImages: allContentfulAsset(
      filter: { contentful_id: { eq: "2LyGL8L6uL9bETboqboI32" } }
    ) {
      nodes {
        node_locale
        description
        fluid(maxWidth: 643, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  }
`;
